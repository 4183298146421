import {Alert, Snackbar} from "@mui/material";


const NotificationComponent = (props: any) => {
    if (props.notification == undefined) return <div/>
    const {setNotification} = props;
    const {message, type, alert} = props.notification;
    const duration = 3000;

    const handleClose = (event: any) => {
        setNotification({});
    }

    const handleOpen = (kind: string) => {
        if (kind == type) return true;
        return false;
    }

    const buildContent = () => {
        return <div>
            <Snackbar open={handleOpen('error')} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
            <Snackbar open={handleOpen('success')} autoHideDuration={duration} onClose={handleClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    }

    return alert == undefined ? <div/> : buildContent();
}
export default NotificationComponent;