import {Outlet} from 'react-router-dom';
import AuthGuard from "app/services/auth/AuthGuard";
import SocketService from "app/services/socket/SocketService";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useCallback, useState} from "react";
import DrawerComponent from "app/pages/components/DrawerComponent";
import FormComponent from "app/pages/components/FormComponent";
import PagePermissionGuard from "app/services/page/PagePermissionGuard";
const mdTheme = createTheme();
export default function BaseLayout() {
    const [open, setOpen] = useState(true);

    const callback = useCallback((open) => {
        setOpen(open);
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <AuthGuard>
                <SocketService>
                    <Box sx={{display: 'flex', flexDirection: 'row', minHeight: '100%'}}>
                        <DrawerComponent openCallBack={callback}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: open ? 'calc(100% - 240px)' : 'calc(100% - 71px)'}}>
                            <CssBaseline/>
                            <FormComponent.appBar title="Israel Farm"/>
                            <PagePermissionGuard>
                                <Outlet/>
                            </PagePermissionGuard>
                        </Box>
                    </Box>
                </SocketService>
            </AuthGuard>
        </ThemeProvider>
    );
}
