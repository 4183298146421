import React from "react"
import Model, {Describe} from "./Model";

export default class RoleModel extends Model implements Describe {
    static getRole = () => [{name: 'Super Admin', id: 1}, {name: 'Department Admin', id: 2}, {name: 'Agronomist', id: 3}, {name: 'Worker', id: 4}];
    public config = {
        actions: {
            AllRoles: {path: null},
        }
    };

    static isSuperAdmin = (permission: any) => permission == RoleModel.getRole()[0]['id'];
}
