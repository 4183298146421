import RoomCardComponent from 'app/pages/dashboard/RoomCardComponent';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ArrowLeft from '@mui/icons-material/ArrowBackIosNew';
import ArrowRight from '@mui/icons-material/ArrowForwardIos';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Styles } from './dashboard/Styles';
import WrapperComponent from './components/WrapperComponent';
import SimpleTableComponent, {
    TableAlignmentTypes,
    TableComponentTypes,
} from 'app/pages/components/SimpleTableComponent';
import Config from 'app/configs/Config';
import FormatTime from 'app/utils/FormatTime';
import { RoutePath } from 'app/routes/Route';
import { useNavigate } from 'react-router-dom';
import RoomModel from 'app/models/RoomModel';
import UserModel from 'app/models/UserModel';
import Model from 'app/models/Model';
import SimpleTablePaginateComponent from './components/SimpleTablePaginateComponent';
import LoadingProgressComponent from './components/LoadingProgressComponent';
import DepartmentSelectComponent from './components/DepartmentSelectComponent';
import ActionModel from 'app/models/ActionModel';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BuildActionsComponent from './components/BuildActionsComponent';
import { Action, Dispatch, Reducer } from '../models/Model/State';
import ActionDefinitionModel from '../models/ActionDefinitionModel';
import StrainModel from '../models/StrainModel';

const DashboardPage = () => {
    const styles = Styles();
    const model = Model.prepare({
        Strain: new StrainModel(),
        Room: new RoomModel(),
        User: new UserModel(),
        ActionDefinition: new ActionDefinitionModel(),
        Action: new ActionModel(),
    });
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const navigate = useNavigate();
    const [roomList, setRoomList] = useState([]);
    const [userData, setUserData] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [strainList, setStrainList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [eaDialog, setEADialog] = useState(false);
    const [actionDefinitionList, setActionDefinitionList] = useState([]);
    const [actionList, setActionList] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('all');
    const tableComponentTypes: typeof TableComponentTypes = TableComponentTypes;

    useEffect(() => {
        model.User.fetch('Current').with({ set: setUserData });
        model.Room.fetch('ByRoomType').with({
            set: setRoomList,
            onSet: onSetRoom,
            params: { id: 'all', skip: 0, limit: 100 },
        });
        model.ActionDefinition.fetch('AllActionDefinitions').with({
            set: setActionDefinitionList,
            params: { skip: 0, limit: 15 },
        });
        model.Room.fetch('ByDepartment').with({ set: setBatchList, params: { id: 'all', skip: 0, limit: 100 } });
        model.Strain.fetch('AllStrains').with({ set: setStrainList });
    }, []);

    const onSetRoom = (payload: any[]) => {
        const group: string | any[] = [];
        const data: any = [];
        if (payload == undefined) return;
        payload.map((record: any) => {
            if (group.indexOf(record.roomType.type) < 0) {
                group.push(record.roomType.type);
                data.push(record);
            }
        });
        setRoomList(data);
        setLoading(false);
        model.Action.fetch('ByDepartment').with({
            set: setActionList,
            onSet: onSetAction,
            dispatch: dispatch,
            params: { id: selectedDepartment, skip: 0, limit: 30 },
        });
        return data;
    };

    const onSetAction = (payload: any) => {
        const actions: any = [];
    };

    const handleRoomCardOnClick = (room: any) => {
        navigate(RoutePath.TYPE + '/' + room.roomType.type);
    };

    const handleIsSelected = (id: string) => selectedItem == id;

    const handleDepartmentOnChange = async (departmentId: string) => {
        setSelectedDepartment(departmentId);
        model.Room.fetch('ByRoomType').with({
            set: setRoomList,
            onSet: onSetRoom,
            params: { id: departmentId, skip: 0, limit: 100 },
        });
    };

    const handlePaginateOnChange = (event: any, value: string) => {
        model.Action.fetch('ByDepartment').with({
            set: setActionList,
            onSet: onSetAction,
            params: { id: selectedDepartment, skip: parseInt(value) * 2 + 1, limit: 30 },
        });
    };

    const buildLeftArrow = () => {
        if (actionList?.length > 0) {
            const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
            return buildLink({
                onClick: scrollPrev,
                isVisible: isFirstItemVisible,
                arrow: <ArrowLeft className={styles.arrow} />,
            });
        } else {
            return <></>;
        }
    };

    const buildRightArrow = () => {
        if (actionList?.length > 0) {
            const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
            return buildLink({
                onClick: scrollNext,
                isVisible: isLastItemVisible,
                arrow: <ArrowRight className={styles.arrow} />,
            });
        } else {
            return <></>;
        }
    };

    const buildLink = (args: { onClick: any; isVisible: boolean; arrow: any }) => {
        const arrow = { disabled: 'arrow-disabled', none: '' };
        return (
            <a href={'#'} onClick={() => args.onClick()} className={!args.isVisible ? arrow.none : arrow.disabled}>
                {args.arrow}
            </a>
        );
    };

    const buildRoomCards = () => {
        const actionCards: any = [];
        const _getType = (result: any) => result.roomType.type;
        const _getIcon = (result: any) => {
            const icon = result.roomType.icon;
            return Config.BACKEND_IMAGE_URL + icon;
        };

        roomList?.forEach((result) => {
            const id = result['id'];
            actionCards.push(
                <RoomCardComponent
                    params={{
                        name: _getType(result),
                        value: result['size'],
                        icon: _getIcon(result),
                    }}
                    itemId={id}
                    onClick={() => handleRoomCardOnClick(result)}
                    isSelected={handleIsSelected(id)}
                    key={id}
                />,
            );
        });
        return actionCards;
    };

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({ type: Action.setIsWorking, payload: true });
        if (state.pageInfo['action'] === 'add') model.Action.add(state).with({ set: setActionList, onSet: _onSet });
        else model.Action.update(state).with({ set: setActionList, onSet: _onSet });
    };

    const handleOnAddClick = (event: any) => {
        if (actionDefinitionList?.length > 0) {
            setEADialog(true);
            dispatch({ type: Action.setData, payload: { description: actionDefinitionList[0]['description'] } });
            dispatch({ type: Action.setPageInfo, payload: { title: 'Add Action', action: 'add' } });
        }
    };

    return (
        <React.Fragment>
            <BuildActionsComponent
                params={{
                    actionDefinitionList: actionDefinitionList,
                    setActionList: setActionList,
                    state: state,
                    dispatcher: dispatch,
                }}
                dialog={{ dialog: eaDialog, setDialog: setEADialog }}
                handleOnSubmit={handleOnSubmit}
            />
            <WrapperComponent>
                <Container fixed sx={{ mt: 2, mb: 4 }} maxWidth={false}>
                    <Grid container sx={{ mb: 10 }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <LoadingProgressComponent isLoading={loading}>
                                <Box
                                    sx={{
                                        clear: 'both',
                                        height: '60px',
                                        float: 'right',
                                        marginRight: '80px',
                                    }}
                                >
                                    <DepartmentSelectComponent handleOnChange={handleDepartmentOnChange} />
                                </Box>
                                <Box sx={{ clear: 'both', height: '10px' }} />
                                <ScrollMenu
                                    itemClassName={styles.format}
                                    LeftArrow={buildLeftArrow}
                                    RightArrow={buildRightArrow}
                                >
                                    {buildRoomCards()}
                                </ScrollMenu>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '88%',
                                        margin: 'auto',
                                        height: '472px',
                                    }}
                                    className={'manage manage-a'}
                                >
                                    <SimpleTableComponent
                                        params={{
                                            headers: [
                                                {
                                                    display: 'Action Name',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'description',
                                                },
                                                {
                                                    display: 'Title',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'title',
                                                },
                                                {
                                                    display: 'Owner',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'user.name',
                                                },
                                                {
                                                    display: 'Info',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'note',
                                                },
                                                {
                                                    display: 'Date',
                                                    type: tableComponentTypes.PLAIN,
                                                    align: TableAlignmentTypes.LEFT,
                                                    field: 'dateCreated',
                                                    format: FormatTime.toDate,
                                                },
                                                {
                                                    display: 'Strain',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'strainInfo',
                                                },
                                                {
                                                    display: 'Room',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'roomInfo',
                                                },
                                                {
                                                    display: 'Batch',
                                                    type: tableComponentTypes.PLAIN,
                                                    field: 'batchInfo',
                                                },
                                            ],
                                            data: actionList?.map((m: any) => {
                                                const findRoomBatch: any = batchList?.find(
                                                    (f: any) => f?.id === m.room,
                                                );
                                                const findBatch: any = findRoomBatch?.batches?.find(
                                                    (f: any) => f?.id === m.batch,
                                                );
                                                const getStrainId =
                                                    m?.associatedRoomProp?.strain || m?.plantsBatchesProp?.strain;
                                                const findStrain: any = strainList?.find(
                                                    (f: any) => f?._id === getStrainId,
                                                );
                                                m.roomInfo = findRoomBatch ? findRoomBatch?.name : '';
                                                m.batchInfo = findBatch ? findBatch?.name : '';
                                                m.strainInfo = findStrain ? findStrain.name : '';
                                                if (typeof m?.user === 'string' && (userData as any)?._id === m.user) {
                                                    m.user = userData;
                                                    return m;
                                                }
                                                return m;
                                            }),
                                        }}
                                    />
                                    <SimpleTablePaginateComponent
                                        list={actionList}
                                        setList={setActionList}
                                        limit={30}
                                        onChange={handlePaginateOnChange}
                                    />
                                </Box>
                            </LoadingProgressComponent>
                        </Grid>
                    </Grid>
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'fixed', right: 75, bottom: 20 }}
                        onClick={handleOnAddClick}
                    >
                        <AddIcon />
                    </Fab>
                </Container>
            </WrapperComponent>
        </React.Fragment>
    );
};

export default DashboardPage;
