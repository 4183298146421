import {format, formatDistanceToNow} from 'date-fns';

class FormatTime {
    static toDate(date: string | number | Date) {
        return format(new Date(date), 'dd MMMM yyyy');
    }

    static toDateTime(date: string | number | Date) {
        if (date === undefined) return "";
        return format(new Date(date), 'MMM dd, yyyy HH:mm');
    }

    static toDateTimeSuffix(date: string | number | Date) {
        return format(new Date(date), 'dd/MM/yyyy hh:mm p');
    }

    static toNow(date: string | number | Date) {
        return formatDistanceToNow(new Date(date), {
            addSuffix: true,
        });
    }
}

export default FormatTime;
