import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import { Styles } from './strain/Styles';
import WrapperComponent from './components/WrapperComponent';
import ActionDefinitionModel from 'app/models/ActionDefinitionModel';
import DialogFormComponent from './components/DialogFormComponent';
import Model from 'app/models/Model';
import { Action, Dispatch, Reducer } from 'app/models/Model/State';
import SimpleTableComponent, {
    TableAlignmentTypes,
    TableComponentTypes,
} from 'app/pages/components/SimpleTableComponent';
import SimpleTableBoxComponent from 'app/pages/components/SimpleTableBoxComponent';
import FormComponent from './components/FormComponent';
import StatusModel from 'app/models/StatusModel';
import FormatTime from 'app/utils/FormatTime';
import UserModel from 'app/models/UserModel';
import RoleModel from 'app/models/RoleModel';
import { ActionDefinitionEnum } from '../enums/ActionDefinition';

const ActionDefinitionPage = (props: any) => {
    const styles = Styles();
    const model = Model.prepare({ ActionDefinition: new ActionDefinitionModel(), User: new UserModel() });
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [pageInfo, setPageInfo] = useState({ title: '' });
    const [userInfo, setUserInfo] = useState({ role: null });
    const [actionDefinitionList, setActionDefinitionList] = useState([]);
    const [eaDialog, setEADialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const tableComponentTypes = TableComponentTypes;
    const tableAlignmentTypes = TableAlignmentTypes;

    useEffect(() => {
        model.User.fetch('Current').with({ set: setUserInfo });
        model.ActionDefinition.fetch('AllActionDefinitions').with({
            set: setActionDefinitionList,
            params: { skip: 0, limit: 15 },
            dispatch: dispatch,
        });
    }, []);

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({ type: Action.setIsWorking, payload: true });

        if (Object.keys(state.selected).length === 0) {
            state.data.status = 'active';
            state.data.type = ActionDefinitionEnum.CUSTOM;
            state.data.farm = (userInfo as any)?.farm;
            model.ActionDefinition.add(state).with({ set: setActionDefinitionList, onSet: _onSet });
        } else {
            model.ActionDefinition.update(state).with({ set: setActionDefinitionList, onSet: _onSet });
        }
    };

    const handleOnKeyPress = (event: any) => {
        const { key } = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({ type: Action.setData, payload: payload });
    };

    const handleOnEditClick = (data: any) => {
        dispatch({ type: Action.setSelected, payload: data });
        setPageInfo({ title: 'Edit ' + data.description });
        setEADialog(true);
    };

    const handleOnDeleteClick = (data: any) => {
        dispatch({ type: Action.setSelected, payload: data });
        setPageInfo({ title: 'Delete ' + data.name });
        setDeleteDialog(true);
    };

    const handleOnDeleteConfirmed = (event: any) => {
        const _onSet = (payload: any) => setDeleteDialog(false);
        model.ActionDefinition.delete(state).with({ set: setActionDefinitionList, onSet: _onSet });
    };

    const handleOnAddClick = (event: any) => {
        dispatch({ type: Action.setSelected, payload: {} });
        setPageInfo({ title: 'Add Action Definition' });
        setEADialog(true);
    };

    const handleOnClickStatus = (data: any) => {
        dispatch({ type: Action.setSelected, payload: data });
        setPageInfo({ title: 'Status ' + data.description });
        setStatusDialog(true);
    };

    const handleOnStatusConfirmed = (event: any) => {
        const _onSet = (payload: any) => setStatusDialog(false);
        const data = state?.selected;
        data.status = data.status == 'active' ? 'inActive' : 'active';
        dispatch({ type: Action.setSingleUpdate, payload: data });
        model.ActionDefinition.update(state).with({ set: setActionDefinitionList, onSet: _onSet });
    };

    const buildEditDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={state}
                    label={'Name'}
                    id={'description'}
                    handleOnChange={handleOnChange}
                    handleOnKeyPress={handleOnKeyPress}
                />
                {/*<FormComponent.selectField state={state} label={'Status'} id={"status"} handleOnChange={handleOnChange} options={StatusModel.getActive()}/>*/}
            </Box>
        );
    };

    const buildEditDialogActions = () => {
        return <FormComponent.button label={'Save'} handleClick={handleOnSubmit} color={'info'} />;
    };

    const buildDeleteDialogContent = () => {
        return (
            <React.Fragment>
                <Typography gutterBottom>Are you sure you want to delete this {state.selected['name']}?</Typography>
            </React.Fragment>
        );
    };

    const buildDeleteDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Cancel'} handleClick={() => setDeleteDialog(false)} />
                <FormComponent.button label={'Delete'} handleClick={handleOnDeleteConfirmed} color={'info'} />
            </React.Fragment>
        );
    };

    const buildStatusIcon = (data: any) => (data.status === 'active' ? 'checked' : 'close');

    const buildActions = () => {
        if (userInfo === null) return [];
        if (RoleModel.isSuperAdmin(userInfo['role'])) {
            return [{ icon: 'edit', event: handleOnEditClick }];
        }
        return [];
    };

    const buildStatus = () => {
        const event = RoleModel.isSuperAdmin(userInfo['role']) ? handleOnClickStatus : null;
        return {
            display: 'Status',
            type: tableComponentTypes.BUTTONS,
            components: [{ icon: buildStatusIcon, event: event }],
        };
    };

    const buildStatusDialogContent = () => {
        return (
            <React.Fragment>
                <Typography gutterBottom>
                    Are you sure you want to enable/disable this {state.selected['name']}?
                </Typography>
            </React.Fragment>
        );
    };

    const buildStatusDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Cancel'} handleClick={() => setStatusDialog(false)} />
                <FormComponent.button label={'Confirm'} handleClick={handleOnStatusConfirmed} color={'info'} />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <DialogFormComponent
                params={{ content: buildEditDialogContent(), actions: buildEditDialogActions(), title: pageInfo.title }}
                dialog={{ dialog: eaDialog, setDialog: setEADialog }}
            />
            <DialogFormComponent
                params={{
                    content: buildDeleteDialogContent(),
                    actions: buildDeleteDialogActions(),
                    title: pageInfo.title,
                }}
                dialog={{ dialog: deleteDialog, setDialog: setDeleteDialog }}
            />
            <DialogFormComponent
                params={{
                    content: buildStatusDialogContent(),
                    actions: buildStatusDialogActions(),
                    title: pageInfo.title,
                }}
                dialog={{ dialog: statusDialog, setDialog: setStatusDialog }}
            />
            <WrapperComponent>
                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        margin: 'auto',
                        height: '612px',
                    }}
                    className={'manage'}
                >
                    <SimpleTableBoxComponent
                        title={'Manage Definitions'}
                        handleOnAddClick={handleOnAddClick}
                        show={true}
                    />
                    <SimpleTableComponent
                        params={{
                            headers: props?.enableCrud
                                ? [
                                      {
                                          display: 'Definition Name',
                                          type: tableComponentTypes.PLAIN,
                                          field: 'description',
                                      },
                                      buildStatus(),
                                      {
                                          display: 'Created',
                                          type: tableComponentTypes.PLAIN,
                                          field: 'dateCreated',
                                          format: FormatTime.toDateTime,
                                      },
                                      {
                                          display: 'Option',
                                          type: tableComponentTypes.BUTTONS,
                                          align: tableAlignmentTypes.LEFT,
                                          components: buildActions(),
                                      },
                                  ]
                                : [
                                      {
                                          display: 'Definition Name',
                                          type: tableComponentTypes.PLAIN,
                                          field: 'description',
                                          withEdit: RoleModel.isSuperAdmin(userInfo['role']) ? handleOnEditClick : null,
                                      },
                                      buildStatus(),
                                      {
                                          display: 'Created',
                                          type: tableComponentTypes.PLAIN,
                                          field: 'dateCreated',
                                          format: FormatTime.toDateTime,
                                      },
                                      {
                                          display: 'Option',
                                          type: tableComponentTypes.BUTTONS,
                                          align: tableAlignmentTypes.LEFT,
                                          components: buildActions(),
                                      },
                                  ],
                            data: actionDefinitionList,
                        }}
                    />
                </Box>
            </WrapperComponent>
        </React.Fragment>
    );
};

export default ActionDefinitionPage;
