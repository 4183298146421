import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean,
    width?: number
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open, width}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        position: 'relative',
        backgroundColor: 'white',
        borderBottom: '1px solid #e7e7e7',
        // marginLeft: 240,
        // width: `calc(100% - ${width}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const AppBarComponent = (props: any) => {
    const {width} = props;
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => setOpen(!open);

    return <AppBar position="absolute" open={open} elevation={0} width={width === undefined ? 240 : width}>
        <Toolbar sx={{pr: '24px'}}>
            {props.children}
        </Toolbar>
    </AppBar>
}

export default AppBarComponent;
