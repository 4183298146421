export default class Config {
    static TITLE = 'Batch Management';
    static REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
    static AUTH_STORAGE_NAME = '__auth__';
    static PATH_LOGIN = 'user/login';
    static PATH_USER = 'user';
    static PATH_EDIT_USER = 'user';
    static PATH_PAGES = 'user/pages';
    static PATH_FETCH_FARM = 'farm';
    static PATH_ROOM = 'room';
    static PATH_BATCH = 'batch';
    static PATH_PLANT = 'plant';
    static PATH_DEPARTMENT = 'department';
    static PATH_ACTION = 'action';
    static PATH_ACTION_DEFINITION = 'actionDefinition';
    static PATH_ROOM_TYPE = 'roomType';
    static PATH_STRAIN = 'strain';
    static PATH_SUPPLIER = 'supplier';
    static PATH_SPRAY_TYPE = 'sprayType';
    static PATH_RESET_PASSWORD = 'password-reset';
    static PATH_REMINDER = 'reminder';
    static BACKEND_IMAGE_URL = 'https://bm-ui-dev.gilix.net/assets/img/dashboard/';
    static SOCKET_HOST = 'ws://localhost:';
    static SOCKET_PORT = 3030;
}
