import {makeStyles} from "@mui/styles";

export const Styles: any = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    option: {
        padding: "1px",
        textAlign: "right",
        height: '60px',
        borderRadius: 10,
        boxShadow: "none"
    },
    select: {
        color: 'black',
        fontSize: '12px'
    },
    pullRight: {
        position: 'absolute',
        right: '30px',
    }
}));
