import styled from 'styled-components';
import {createStyles, makeStyles} from "@mui/styles";

export const LoginStyle = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            width: 400,
            margin: 'auto',
        },
        loginButton: {

            flexGrow: 2,
            borderRadius: 15,
        },
        header: {
            textAlign: 'center',
            color: 'black',
        },
        card: {
            padding: 20,
        },
        loginIcon: {
            fontSize: 80,
            color: '#191c24',
        },
    }),
);

export class LoginStyled {
    static DivProgress = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
    `;
    static DivErrorMessage = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: red;
    `;
}
