import {makeStyles} from "@mui/styles";

export const Styles: any = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    format: {
        backgroundColor: 'transparent',
        marginBottom: 20,
        clear: 'both',
        display: 'block'
    },
    arrow: {
        fontSize: 90,
        marginTop: 50,
        color: 'grey',
        backgroundColor: 'transparent',
        fontWeight: 'normal'
    },
    menu: {
        '& span, & svg': {
            fontSize: '3rem'
        }
    },
    title: {
        marginBottom: 50
    },
    department: {
        position: 'absolute',
        right: '160px',
        width: '200px'
    },
    pullRight: {
        position: 'absolute',
        right: '30px',
    }
}));
