import Config from "app/configs/Config";
import Model, {Describe} from "./Model";
import React from "react";

export default class DepartmentModel extends Model implements Describe {
    config = {
        actions: {
            AllDepartments: {path: Config.PATH_DEPARTMENT},
            SaveData: {path: Config.PATH_DEPARTMENT},
            UpdateData: {path: Config.PATH_DEPARTMENT},
            DeleteData: {path: Config.PATH_DEPARTMENT},
        }
    };
}
