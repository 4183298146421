import {Outlet} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/styles";

export class AuthStyle {
    static RootStyle = styled('div')({
        display: 'flex',
        minHeight: '100%',
        overflow: 'hidden',
        margin: "auto",
        paddingTop: '5%',
        backgroundColor: '#f5f5f5'
    });

    static MainStyle = styled('div')(({theme}) => ({
        flexGrow: 1,
        overflow: 'auto',
        minHeight: '100%',
    }));

}

const mdTheme = createTheme();
export default function AuthLayout() {
    return (
        <ThemeProvider theme={mdTheme}>
            <AuthStyle.RootStyle>
                <AuthStyle.MainStyle>
                    <CssBaseline/>
                    <Outlet/>
                </AuthStyle.MainStyle>
            </AuthStyle.RootStyle>
        </ThemeProvider>
    );
}
