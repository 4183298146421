export default class Strings {
    static isDefinedThen(args: { criteria: any, ifTrue: string, ifFalse: string }) {
        if (args.criteria == undefined) {
            return args.ifFalse;
        }
        return args.criteria;
    }

    static toCamelCase = (value: string) => {
        return value.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function (match) {
            return match.charAt(match.length - 1).toUpperCase();
        });
    }

    static toEllipses = (value: string) => {
        if (value == undefined) return "";
        const length = 18;
        const ellipsis = value.length >= length ? '...' : '';
        return value.substring(0, length) + ellipsis;
    }
}