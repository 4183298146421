import Config from 'app/configs/Config';
import Model, { Describe } from './Model';

export default class SprayTypeModel extends Model implements Describe {
    config = {
        actions: {
            AllSprayType: { path: Config.PATH_SPRAY_TYPE },
            SaveData: { path: Config.PATH_SPRAY_TYPE },
            UpdateData: { path: Config.PATH_SPRAY_TYPE },
            DeleteData: { path: Config.PATH_SPRAY_TYPE },
        },
    };
}
