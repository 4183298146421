import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Box, Collapse, Container, List, Tooltip} from "@mui/material";
import MenuModel from "app/models/MenuModel";
import {makeStyles} from "@mui/styles";
import UserBoxComponent from "./UserBoxComponent";
import {useNavigate} from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import MenuIconOpen from '@mui/icons-material/Menu';

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: 240,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const Styles: any = makeStyles((theme) => ({
    menu: {
        '& span, & svg': {
            fontSize: '0.90em'
        }
    },
    logo: {
        textAlign: "center",
        opacity: 0.9,
        lineHeight: '0.92em'
    },
    describe: {
        fontSize: '11px',
        display: 'block'
    }
}));

const DrawerComponent = ({openCallBack}: any) => {
    const styles = Styles();
    const navigate = useNavigate();
    const menuModel = new MenuModel();
    const [open, setOpen] = React.useState(true);
    const [openList, setOpenList] = React.useState({});
    const toggleDrawer = () => {
        setOpen(!open);
        openCallBack(!open);
    };

    const hasChildren = (data: any) => {
        const {items} = data.item;
        if (items === undefined) return false;
        if (items.constructor !== Array) return false;
        if (items.length === 0) return false;
        return true;
    }

    const buildMenu = () => {
        return menuModel.drawer.map((item, key) => <MenuItem key={key} item={item}/>);
    }

    const SingleLevel = (props: any) => {
        const {item} = props.item;
        return (
            <ListItem button onClick={() => navigate(item.path)}>
                {BuildListItem(item, undefined)}
            </ListItem>
        );
    };

    const handlerListOpen = (el: any) => {
        const target = el.currentTarget;
        setOpenList((prev: any) => ({
            ...prev,
            [target.getAttribute("id")]: !prev[target.getAttribute("id")]
        }));
    };

    const MultiLevel = (props: any) => {
        const {item} = props.item;
        const {items: children} = item;

        return (
            <React.Fragment>
                <ListItem id={item.name} button onClick={handlerListOpen}>
                    {BuildListItem(item, openList[item.name as keyof typeof openList] ?? false)}
                </ListItem>
                <Collapse in={openList[item.name as keyof typeof openList]} timeout="auto" unmountOnExit>
                    <List sx={{paddingLeft: open ? '30px' : '0px'}} component="div" disablePadding>
                        {children.map((child: any, key: any) => (
                            <MenuItem key={key} item={child}/>
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    const topToggle = () => {
        if (open) {
            return (
                <Container className={styles.logo}
                           sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span>MEADOW
                        <small className={styles.describe}>Batch Manager</small>
                    </span>
                    <IconButton onClick={toggleDrawer}>
                        <MenuIconOpen/>
                    </IconButton>
                </Container>
            )
        }
        return (
            <IconButton onClick={toggleDrawer}>
                <MenuIconOpen/>
            </IconButton>
        )
    }

    const getExpandedIcon = (openMenu: boolean | undefined) => {
        if (openMenu !== undefined) {
            return (
                <>{openMenu ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</>
            )
        }
        return <></>
    }

    const BuildListItem = (item: any, openMenu: boolean | undefined) => {
        if (open) {
            return (<>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} className={styles.menu}/>
                    {getExpandedIcon(openMenu)}
                </>
            )
        }
        return (<>
                <Tooltip title={item.name}>
                    <ListItemIcon>{item.icon}{getExpandedIcon(openMenu)}</ListItemIcon>
                </Tooltip>
            </>
        )
    }

    const MenuItem = (item: any) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item}/>;
    };

    return <Drawer variant="permanent" open={open}>
        <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', px: [1]}}>
            {topToggle()}
        </Toolbar>
        <Divider/>
        <Box pt={5} pb={5}>
            <UserBoxComponent menuOpened={open}/>
            <List>{buildMenu()}</List>
        </Box>
        <Divider/>
    </Drawer>
}

export default DrawerComponent;
