import {useEffect, useState} from 'react';

import UserModel from "app/models/UserModel";
import {Link as RouterLink} from "react-router-dom";
import MenuModel from "app/models/MenuModel";
import Model from "app/models/Model";
import {Avatar, Box, Link, styled, Typography} from "@mui/material";
import Format from "app/utils/Format";
import RoleModel from "app/models/RoleModel";

const StyledUser = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
}));

const UserBoxComponent = (props: any) => {
    const model = Model.prepare({User: new UserModel()});
    const menuModel = new MenuModel();
    const [userData, setUserData] = useState({photo: '', name: '', role: ''});

    useEffect(() => {
        model.User.fetch("Current").with({set: setUserData});
    }, []);

    return userData === null ? <div/> :
        <Box sx={{mb: 5, mx: 2.5}}>
            <Link sx={{width: '100%', display: 'flex', justifyContent: 'center'}} underline="none" component={RouterLink} to={menuModel.profile.path}>
                {props.menuOpened ?
                    <StyledUser>
                        <Avatar src={userData.photo} alt={userData.name}/>
                        <Box sx={{ml: 2}}>
                            <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                                {userData.name}
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary', fontSize: '11px'}}>
                                {Format.populate({list: RoleModel.getRole(), id: userData.role, name: 'name'})}
                            </Typography>
                        </Box>
                    </StyledUser>
                    : <Avatar src={userData.photo} alt={userData.name}/>}

            </Link>
        </Box>
}

export default UserBoxComponent;
