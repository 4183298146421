import React from 'react';
import {Card, Grid, Stack, Typography} from '@mui/material';
import {MakeStyles, StyledRoomItem} from "./Styles";

export default function Index(props: any) {
    const {name, value, icon, width} = props.params
    const styles = MakeStyles();

    const buildTimeline = () => {
        return (
            <div className={'timeline'}>
                <div className="timeline-end"><span>End</span></div>
                <div className="timeline-start"><span>Start</span></div>
                <div className="timeline-content">
                    <div className="timeline-block">
                        <Stack spacing={6}>
                            <StyledRoomItem className={styles.bgTransparent}>
                                <Grid container>
                                    <Grid item md={5} xs={5}><span>4 weeks</span></Grid>
                                    <Grid item md={2} xs={2}>
                                        <div className="timeline-horizontal"/>
                                    </Grid>
                                    <Grid item md={5} xs={5}> <span>1000</span></Grid>
                                </Grid>
                            </StyledRoomItem>
                            <StyledRoomItem className={styles.bgTransparent}>
                                <Grid container>
                                    <Grid item md={5} xs={5}><span>6 weeks</span></Grid>
                                    <Grid item md={2} xs={2}>
                                        <div className="timeline-horizontal"/>
                                    </Grid>
                                    <Grid item md={5} xs={5}> <span>44000</span></Grid>
                                </Grid>
                            </StyledRoomItem>
                        </Stack>
                    </div>
                </div>
                <div className="timeline-vertical"/>
            </div>)
    }

    return (
        <div style={{width: width ?? 250}} onClick={props.onClick}>
            <Card className={styles.card} raised={false} sx={{borderRadius: '5%', backgroundColor: props.isSelected ? '#F1EAEA' : 'white'}} elevation={1}>
                <Grid container spacing={0}>
                    <Grid item md={7}>
                        <Stack spacing={0}>
                            <StyledRoomItem className={styles.alignLeftPad}>
                                <Typography gutterBottom variant="h4" component="h3">
                                    {value}
                                </Typography>
                            </StyledRoomItem>
                            <StyledRoomItem className={styles.alignLeftPad}>
                                <img
                                    alt={name}
                                    height="50"
                                    src={icon}
                                />
                            </StyledRoomItem>
                            <StyledRoomItem className={styles.alignLeftPad}>
                                <Typography gutterBottom variant="h6" component="h3">
                                    {name}
                                </Typography>
                            </StyledRoomItem>
                        </Stack>
                    </Grid>
                    <Grid item md={5}>
                        {buildTimeline()}
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}