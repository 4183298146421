import Config from "app/configs/Config";
import Model, {Describe} from "./Model";

export default class RoomModel extends Model implements Describe {
    pathAction = '/actions';
    pathDepartment = '/department';
    pathType = '/type';

    config = {
        actions: {
            AllRooms: {path: Config.PATH_ROOM},
            ByDepartment: {path: Config.PATH_ROOM + this.pathDepartment},
            AndBatchActions: {path: Config.PATH_ROOM + this.pathAction},
            ByRoomType: {path: Config.PATH_ROOM + this.pathType},
            AllARoomTypes: {path: Config.PATH_ROOM_TYPE},
            AllActionDefinitions: {path: Config.PATH_ACTION_DEFINITION},
            SaveData: {path: Config.PATH_ROOM},
            UpdateData: {path: Config.PATH_ROOM},
            DeleteData: {path: Config.PATH_ROOM},
        }
    };
}
