import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Action } from 'app/models/Model/State';
import DialogFormComponent from './DialogFormComponent';
import FormComponent from './FormComponent';
import Format from 'app/utils/Format';
import RoomBatchSelectComponent from './RoomBatchSelectComponent';
import BuildActionDefinitionTypeDialog from './BuildActionDefinitionTypeDialog';

const BuildActionsComponent = (props: any) => {
    const { actionDefinitionList, setActionList, state, dispatcher, setDepartment, setRoom, setBatch } = props.params;
    const { dialog, setDialog } = props.dialog;
    const { handleOnSubmit } = props;
    const [eaDialog, setEADialog] = useState(false);
    const [isBlank, setBlank] = useState(true);
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [getPayload, setPayload] = useState({});
    const handleOnChange = (event: any) => {
        if (event.target.id === 'description') {
            setType(actionDefinitionList[event.target.options.selectedIndex - 1]?.type);
            setBlank(false);
            setEADialog(true);
            setTitle(event.target.value);
        }
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatcher({ type: Action.setData, payload: payload });
    };
    const handleSaveAction = (event: any) => {
        setPayload(event);
        setEADialog(false);
    };

    const handleSubmit = () => {
        state.data = {
            ...state.data,
            ...getPayload,
        };
        handleOnSubmit();
    };

    const handleOnCancel = (event: any) => {
        setEADialog(false);
    };
    const handleOnKeyPress = (event: any) => {
        return;
    };

    const buildEditDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={state}
                    label={'Title'}
                    id={'title'}
                    handleOnChange={handleOnChange}
                    handleOnKeyPress={handleOnKeyPress}
                />

                <BuildActionDefinitionTypeDialog
                    params={{ type: type, title: title }}
                    dialog={{ dialog: eaDialog, setDialog: setEADialog }}
                    handleOnSubmit={handleSaveAction}
                    handleOnCancel={handleOnCancel}
                />
                <FormComponent.selectField
                    state={state}
                    label={'Action'}
                    id={'description'}
                    handleOnChange={handleOnChange}
                    isBlank={isBlank}
                    options={Format.toSelectOptions({
                        list: actionDefinitionList,
                        name: 'description',
                        nameOnly: true,
                    })}
                />
                <RoomBatchSelectComponent
                    state={state}
                    dispatcher={dispatcher}
                    set={setActionList}
                    setDepartment={setDepartment}
                    setRoom={setRoom}
                    setBatch={setBatch}
                    handleOnChange={handleOnChange}
                />
                <FormComponent.textArea state={state} label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                {/*<FormComponent.groupCheckBox state={state} label={'Select Roles'} id={"role"}*/}
                {/*                             handleOnChange={handleOnChange} options={RoleModel.getRole()}/>*/}
            </Box>
        );
    };

    const buildEditDialogActions = () => {
        return (
            <React.Fragment>
                <Button onClick={handleSubmit} variant="contained" size="large" color="primary">
                    Save
                </Button>
            </React.Fragment>
        );
    };

    const handleOnCloseDialog = () => {
        dispatcher({ type: Action.setReset, payload: Math.random() });
    };

    return (
        <DialogFormComponent
            params={{
                content: buildEditDialogContent(),
                actions: buildEditDialogActions(),
                title: state.pageInfo.title,
                onClose: handleOnCloseDialog,
            }}
            dialog={{ dialog: dialog, setDialog: setDialog }}
        />
    );
};

export default BuildActionsComponent;
