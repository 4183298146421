import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import { Styles } from './strain/Styles';
import WrapperComponent from './components/WrapperComponent';
import RoomTypeModel from 'app/models/RoomTypeModel';
import DialogFormComponent from './components/DialogFormComponent';
import Model from 'app/models/Model';
import { Action, Dispatch, Reducer } from 'app/models/Model/State';
import SimpleTableComponent, {
    TableAlignmentTypes,
    TableComponentTypes,
} from 'app/pages/components/SimpleTableComponent';
import SimpleTableBoxComponent from 'app/pages/components/SimpleTableBoxComponent';
import FormComponent from './components/FormComponent';
import StatusModel from 'app/models/StatusModel';
import SimpleTablePaginateComponent from './components/SimpleTablePaginateComponent';
import FormatTime from 'app/utils/FormatTime';
import RoleModel from 'app/models/RoleModel';
import UserModel from 'app/models/UserModel';

const RoomTypePage = () => {
    const styles = Styles();
    const model = Model.prepare({ RoomType: new RoomTypeModel(), User: new UserModel() });
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [pageInfo, setPageInfo] = useState({ title: '' });
    const [userInfo, setUserInfo] = useState({ role: null });
    const [roomTypeList, setRoomTypeList] = useState([]);
    const [eaDialog, setEADialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const tableComponentTypes = TableComponentTypes;
    const tableAlignmentTypes = TableAlignmentTypes;

    useEffect(() => {
        model.User.fetch('Current').with({ set: setUserInfo });
        model.RoomType.fetch('AllRoomTypes').with({ set: setRoomTypeList, dispatch: dispatch });
    }, []);

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({ type: Action.setIsWorking, payload: true });

        if (Object.keys(state.selected).length === 0) {
            model.RoomType.add(state).with({ set: setRoomTypeList, onSet: _onSet });
        } else {
            model.RoomType.update(state).with({ set: setRoomTypeList, onSet: _onSet });
        }
    };

    const handleOnKeyPress = (event: any) => {
        const { key } = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({ type: Action.setData, payload: payload });
    };

    const handleOnEditClick = (data: any) => {
        dispatch({ type: Action.setSelected, payload: data });
        setPageInfo({ title: 'Edit ' + data.type });
        setEADialog(true);
    };

    const handleOnDeleteClick = (data: any) => {
        dispatch({ type: Action.setSelected, payload: data });
        setPageInfo({ title: 'Delete ' + data.type });
        setDeleteDialog(true);
    };

    const handleOnDeleteConfirmed = (event: any) => {
        const _onSet = (payload: any) => setDeleteDialog(false);
        model.RoomType.delete(state).with({ set: setRoomTypeList, onSet: _onSet });
    };

    const handleOnAddClick = (event: any) => {
        dispatch({ type: Action.setSelected, payload: {} });
        setPageInfo({ title: 'Add RoomType' });
        setEADialog(true);
    };

    const handleOnClickStatus = (data: any) => {
        dispatch({ type: Action.setSelected, payload: data });
        setPageInfo({ title: 'Status ' + data.type });
        setStatusDialog(true);
    };

    const handleOnStatusConfirmed = (event: any) => {
        const _onSet = (payload: any) => setStatusDialog(false);
        const data = state?.selected;
        data.status = data.status == 'active' ? 'inActive' : 'active';
        dispatch({ type: Action.setSingleUpdate, payload: data });
        model.RoomType.update(state).with({ set: setRoomTypeList, onSet: _onSet });
    };

    const handlePaginateOnChange = (event: any, value: string) => {
        model.RoomType.fetch('AllRoomTypes').with({
            set: setRoomTypeList,
            dispatch: dispatch,
            params: { skip: parseInt(value) * 2 + 1, limit: 30 },
        });
    };

    const buildEditDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={state}
                    label={'Name'}
                    id={'type'}
                    handleOnChange={handleOnChange}
                    handleOnKeyPress={handleOnKeyPress}
                />
                <FormComponent.selectField
                    state={state}
                    label={'Status'}
                    id={'status'}
                    handleOnChange={handleOnChange}
                    options={StatusModel.getActive()}
                />
            </Box>
        );
    };

    const buildEditDialogActions = () => {
        return <FormComponent.button label={'Save'} handleClick={handleOnSubmit} color={'info'} />;
    };

    const buildDeleteDialogContent = () => {
        return (
            <React.Fragment>
                <Typography gutterBottom>Are you sure you want to delete this {state.selected['name']}?</Typography>
            </React.Fragment>
        );
    };

    const buildDeleteDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Cancel'} handleClick={() => setDeleteDialog(false)} />
                <FormComponent.button label={'Delete'} handleClick={handleOnDeleteConfirmed} color={'info'} />
            </React.Fragment>
        );
    };

    const buildStatusDialogContent = () => {
        return (
            <React.Fragment>
                <Typography gutterBottom>
                    Are you sure you want to enable/disable this {state.selected['name']}?
                </Typography>
            </React.Fragment>
        );
    };

    const buildStatusDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Cancel'} handleClick={() => setStatusDialog(false)} />
                <FormComponent.button label={'Confirm'} handleClick={handleOnStatusConfirmed} color={'info'} />
            </React.Fragment>
        );
    };

    const buildStatusIcon = (data: any) => (data.status === 'active' ? 'checked' : 'close');

    const buildActions = () => {
        if (userInfo === null) return [];
        if (RoleModel.isSuperAdmin(userInfo['role'])) {
            return [
                { icon: 'edit', event: handleOnEditClick },
                { icon: 'delete', event: handleOnDeleteClick },
            ];
        }
        return [];
    };

    const buildStatus = () => {
        const event = RoleModel.isSuperAdmin(userInfo['role']) ? handleOnClickStatus : null;
        return {
            display: 'Status',
            type: tableComponentTypes.BUTTONS,
            components: [{ icon: buildStatusIcon, event: event }],
        };
    };

    return (
        <React.Fragment>
            <DialogFormComponent
                params={{ content: buildEditDialogContent(), actions: buildEditDialogActions(), title: pageInfo.title }}
                dialog={{ dialog: eaDialog, setDialog: setEADialog }}
            />
            <DialogFormComponent
                params={{
                    content: buildDeleteDialogContent(),
                    actions: buildDeleteDialogActions(),
                    title: pageInfo.title,
                }}
                dialog={{ dialog: deleteDialog, setDialog: setDeleteDialog }}
            />
            <DialogFormComponent
                params={{
                    content: buildStatusDialogContent(),
                    actions: buildStatusDialogActions(),
                    title: pageInfo.title,
                }}
                dialog={{ dialog: statusDialog, setDialog: setStatusDialog }}
            />
            <WrapperComponent>
                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        margin: 'auto',
                        height: '612px',
                    }}
                    className={'manage'}
                >
                    <SimpleTableBoxComponent
                        title={'Manage Room Types'}
                        handleOnAddClick={handleOnAddClick}
                        show={RoleModel.isSuperAdmin(userInfo['role'])}
                    />
                    <SimpleTableComponent
                        params={{
                            headers: [
                                {
                                    display: 'Type',
                                    type: tableComponentTypes.PLAIN,
                                    field: 'type',
                                    withEdit: RoleModel.isSuperAdmin(userInfo['role']) ? handleOnEditClick : null,
                                },
                                buildStatus(),
                                {
                                    display: 'Created',
                                    type: tableComponentTypes.PLAIN,
                                    field: 'dateCreated',
                                    format: FormatTime.toDateTime,
                                },
                                {
                                    display: 'Option',
                                    type: tableComponentTypes.BUTTONS,
                                    align: tableAlignmentTypes.LEFT,
                                    components: buildActions(),
                                },
                            ],
                            data: roomTypeList,
                        }}
                    />
                    <SimpleTablePaginateComponent
                        list={roomTypeList}
                        setList={setRoomTypeList}
                        limit={15}
                        onChange={handlePaginateOnChange}
                    />
                </Box>
            </WrapperComponent>
        </React.Fragment>
    );
};

export default RoomTypePage;
