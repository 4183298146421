import Config from "app/configs/Config";
import Model from "./Model";

export default class BatchModel extends Model {
    config = {
        actions: {
            AllBatches: {path: Config.PATH_BATCH},
            ByRoom: {path: Config.PATH_BATCH},
            SaveData: {path: Config.PATH_BATCH},
            UpdateData: {path: Config.PATH_BATCH},
            DeleteData: {path: Config.PATH_BATCH},
        }
    }
}