import * as React from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DialogFormComponent from '../components/DialogFormComponent';
import FormComponent from '../components/FormComponent';
import { Action, Dispatch, Reducer } from 'app/models/Model/State';
import { useEffect, useReducer, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Model from 'app/models/Model';
import ReminderModel from 'app/models/ReminderModel';
import UserModel from 'app/models/UserModel';
import moment from 'moment';
import Format from 'app/utils/Format';

const RightSidePaneComponent = (props: any) => {
    const { paneState, setPaneState } = props;
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [eaDialog, setEADialog] = useState(false);
    const model = Model.prepare({ Reminder: new ReminderModel(), Users: new UserModel() });
    const [reminderList, setReminderList] = useState([]);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        model.Reminder.fetch('AllReminders').with({ set: setReminderList, dispatch: dispatch });
        model.Users.fetch('AllUsers').with({ set: setUserList, dispatch: dispatch });
    }, []);

    const handleOnAddClick = () => {
        dispatch({ type: Action.setReset, payload: null });
        setEADialog(true);
        setPaneState({ isPaneOpen: false });
    };

    const buildEditDialogActions = () => {
        return <FormComponent.button label={'Submit'} handleClick={handleOnSubmit} color={'info'} />;
    };

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
            setPaneState({ isPaneOpen: true });
        };
        dispatch({ type: Action.setIsWorking, payload: true });
        model.Reminder.add(state).with({ set: setReminderList, onSet: _onSet });
    };

    const handleOnChangeUser = (arr: any[]) => {
        const payload: any = [];
        payload.mention = arr.map((item) => {
            return item.id;
        });
        dispatch({ type: Action.setData, payload: payload });
    };

    const buildEditDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={state}
                    label={'Text'}
                    id={'text'}
                    handleOnChange={handleOnChange}
                    handleOnKeyPress={handleOnKeyPress}
                />

                <FormComponent.multiSelect
                    state={state}
                    label={'Mention'}
                    id={'mention'}
                    handleOnChange={handleOnChangeUser}
                    options={Format.toSelectOptions({ list: userList, name: 'name' })}
                />
            </Box>
        );
    };

    const handleOnChange = (event: any) => {
        const payload: any = [];
        if (event.target.id === undefined) return;

        payload[event.target.id] = event.target.value;
        dispatch({ type: Action.setData, payload: payload });
    };

    const handleOnKeyPress = (event: any) => {
        const { key } = event;
        if (key === 'Enter') paneState.isButtonDisabled || handleOnSubmit(event);
    };

    const niceDate = (date: any) => {
        return moment(date).format('MMMM d, YYYY HH:mm');
    };

    const _buildSide = () => {
        return (
            <>
                <DialogFormComponent
                    params={{
                        content: buildEditDialogContent(),
                        actions: buildEditDialogActions(),
                        title: 'Add Reminder',
                        dispatch: dispatch,
                    }}
                    dialog={{ dialog: eaDialog, setDialog: setEADialog }}
                />

                <SlidingPane
                    closeIcon={<small>x</small>}
                    width="400px"
                    className="sliding-pane-main"
                    overlayClassName="sliding-pane-overlay"
                    isOpen={paneState.isPaneOpen}
                    onRequestClose={() => {
                        setPaneState({ isPaneOpenLeft: false, isPaneOpen: false });
                    }}
                    title="Reminders"
                >
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'fixed', right: 25, bottom: 20, zIndex: 1 }}
                        onClick={handleOnAddClick}
                        size="small"
                    >
                        <AddIcon />
                    </Fab>

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {reminderList.map(function (item: any, i: number) {
                            return (
                                <div key={item._id}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt={item.user.name} src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.text}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        Mentions:
                                                        {item.mention.map(function (itemi: any) {
                                                            return (
                                                                <React.Fragment key={itemi._id}>
                                                                    @{itemi.name}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Typography>

                                                    <br />
                                                    {niceDate(item.createdAt)}
                                                    <br />
                                                    {item.user.name}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            );
                        })}
                    </List>
                </SlidingPane>
            </>
        );
    };
    return _buildSide();
};

export default RightSidePaneComponent;
