import Config from "app/configs/Config";
import Model from "./Model";

export default class PlantModel extends Model {
    config = {
        actions: {
            AllPlants: {path: Config.PATH_PLANT},
            SaveData: {path: Config.PATH_PLANT},
            UpdateData: {path: Config.PATH_PLANT},
            DeleteData: {path: Config.PATH_PLANT},
        }
    }
}