import {Request} from "./Request";
import {Mode} from "app/models/Model";

export class Get extends Request implements Mode {
    method = 'GET';

    constructor(action: string) {
        super();
    }
}

