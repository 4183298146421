import Config from "app/configs/Config";
import Model from "./Model";

export default class ActionModel extends Model {
    pathRoom = '/room';
    pathRDepartment = '/department';
    pathBatch = '/batch';

    config = {
        actions: {
            AllActions: {path: Config.PATH_ACTION},
            ByRoom: {path: Config.PATH_ACTION + this.pathRoom},
            ByBatch: {path: Config.PATH_ACTION + this.pathBatch},
            ByDepartment: {path: Config.PATH_ACTION + this.pathRDepartment},
            SaveData: {path: Config.PATH_ACTION},
            UpdateData: {path: Config.PATH_ACTION},
            DeleteData: {path: Config.PATH_ACTION},
        }
    }
}