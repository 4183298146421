import Config from "app/configs/Config";
import Model from "./Model";

export default class ActionDefinitionModel extends Model {
    config = {
        actions: {
            AllActionDefinitions: {path: Config.PATH_ACTION_DEFINITION},
            SaveData: {path: Config.PATH_ACTION_DEFINITION},
            UpdateData: {path: Config.PATH_ACTION_DEFINITION},
            DeleteData: {path: Config.PATH_ACTION_DEFINITION},
        }
    }
}
