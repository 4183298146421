import {StorageService} from 'app/services/storage/StorageService';
import {AxiosInstance} from "app/services/api/ApiService";

const ApiInterceptor = async () => {
    const onRequest = async () => {
        await StorageService.read().then((result) => {
            if (result == null) return Promise.reject({error: true});
            const token = result['token'] || '';
            AxiosInstance.interceptors.request.use(
                async (config) => {
                    config.headers = {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    };
                    return config;
                },
                (error) => {
                    Promise.reject(error);
                },
            );
        });
    };

    const onResponse = async () => {
        AxiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            async function (error) {
                const originalRequest = error.config;
                if (error.response.status === 403 && !originalRequest._retry) {
                }
                return Promise.reject(error);
            },
        );
    };

    await onRequest();
    await onResponse();
}

export default ApiInterceptor;