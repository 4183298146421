import Config from 'app/configs/Config';
import Model from './Model';

export default class ReminderModel extends Model {
    config = {
        actions: {
            AllReminders: { path: Config.PATH_REMINDER },
            SaveData: { path: Config.PATH_REMINDER },
            //UpdateData: { path: Config.PATH_REMINDER },
            //DeleteData: { path: Config.PATH_REMINDER },
        },
    };
}
