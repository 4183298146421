import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useReducer, useState} from "react";
import {Styles} from './batch/Styles';
import WrapperComponent from "./components/WrapperComponent";
import DialogFormComponent from "./components/DialogFormComponent";
import Model from "app/models/Model";
import {Action, Dispatch, Reducer} from "app/models/Model/State";
import SimpleTableComponent, {TableAlignmentTypes, TableComponentTypes} from "app/pages/components/SimpleTableComponent";
import SimpleTableBoxComponent from "app/pages/components/SimpleTableBoxComponent";
import FormComponent from "./components/FormComponent";
import StatusModel from "app/models/StatusModel";
import SimpleTablePaginateComponent from "./components/SimpleTablePaginateComponent";
import BatchModel from "app/models/BatchModel";
import StrainModel from "app/models/StrainModel";
import DepartmentRoomSelectComponent from "./components/DepartmentRoomSelectComponent";
import Format from "app/utils/Format";
import FormatTime from "app/utils/FormatTime";
import UserModel from "app/models/UserModel";
import RoleModel from "app/models/RoleModel";
import DepartmentModel from "app/models/DepartmentModel";
import RoomBatchSelectComponent from "./components/RoomBatchSelectComponent";
import BatchInfoDialogComponent from "./components/BatchInfoDialog";

const BatchPage = () => {
    const styles = Styles();
    const model = Model.prepare({Batch: new BatchModel(), Strain: new StrainModel(), User: new UserModel(), Department: new DepartmentModel()});
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [pageInfo, setPageInfo] = useState({title: ''});
    const [userInfo, setUserInfo] = useState({role: null});
    const [batchList, setBatchList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [strainList, setStrainList] = useState([]);
    const [eaDialog, setEADialog] = useState(false);
    const [batchInfoDialog, setBatchInfoDialog] = useState(false);
    const [batchInfo, setBatchInfo] = useState({});
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const tableComponentTypes = TableComponentTypes;
    const tableAlignmentTypes = TableAlignmentTypes;

    useEffect(() => {
        model.Department.fetch("AllDepartments").with({set: setDepartmentList});
        model.User.fetch("Current").with({set: setUserInfo});
        model.Strain.fetch("AllStrains").with({set: setStrainList});

    }, [])

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({type: Action.setIsWorking, payload: true});
        if (Object.keys(state.selected).length === 0) model.Batch.add(state).with({set: setBatchList, onSet: _onSet});
        else {
            model.Batch.update(state).with({set: setBatchList, onSet: _onSet});
        }
    };

    const handleOnKeyPress = (event: any) => {
        const {key} = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    }

    const handleOnChange = (event: any) => {
        const payload: any = [];
        if(event.target.id === undefined) return;

        payload[event.target.id] = event.target.value;
        dispatch({type: Action.setData, payload: payload});
    }

    const handleOnEditClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        setPageInfo({title: 'Edit ' + data.name});
        setEADialog(true);
    }

    const showBatchInfo = (data: any) => {
        setBatchInfo(data);
        setBatchInfoDialog(true);
    }

    const handleOnDeleteClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        setPageInfo({title: 'Delete ' + data.name});
        setDeleteDialog(true);
    }

    const handleOnDeleteConfirmed = (event: any) => {
        const _onSet = (payload: any) => setDeleteDialog(false);
        model.Batch.delete(state).with({set: setBatchList, onSet: _onSet});
    }

    const handleOnAddClick = (event: any) => {
        dispatch({type: Action.setReset, payload: null});
        setPageInfo({title: 'Add Batch'});
        setEADialog(true);
    }

    const handleOnClickStatus = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        setPageInfo({title: 'Status ' + data.name});
        setStatusDialog(true);
    }

    const handlePaginateOnChange = (event: any, value: string) => {
        model.Batch.fetch("ByDepartment").with({set: setBatchList, dispatch: dispatch, params: {skip: parseInt(value) * 2 + 1, limit: 15}});
    }

    const handleOnStatusConfirmed = (event: any) => {
        const _onSet = (payload: any) => setStatusDialog(false);
        const data = state?.selected;
        data.status = data.status == 'active' ? 'inActive' : 'active';
        dispatch({type: Action.setSingleUpdate, payload: data});
        model.Batch.update(state).with({set: setBatchList, onSet: _onSet});
    }

    const buildEditDialogContent = () => {
        return <Box mb={2}>
            <FormComponent.qrCode data={state.selected.id}/>
            <RoomBatchSelectComponent state={state} dispatcher={dispatch} handleOnChange={handleOnChange} noBatch={true}/>
            <FormComponent.textField state={state} label={'Name'} id={"name"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.selectField state={state} label={'Strain'} id={"strain"} handleOnChange={handleOnChange} dispatch={dispatch}
                                       options={Format.toSelectOptions({list: strainList, name: 'name'})}/>
            <FormComponent.textField state={state} label={'Note'} id={"note"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.counterButtons state={state} label={'Total Plants'} id={"totalPlants"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.selectField state={state} label={'Status'} id={"status"} handleOnChange={handleOnChange} options={StatusModel.getActive()} dispatch={dispatch}/>
        </Box>;
    }

    const buildEditDialogActions = () => {
        return <FormComponent.button label={'Save'} handleClick={handleOnSubmit} color={'info'}/>
    }

    const buildDeleteDialogContent = () => {
        return <React.Fragment><Typography gutterBottom>Are you sure you want to delete this {state.selected['name']}?</Typography></React.Fragment>;
    }

    const buildDeleteDialogActions = () => {
        return <React.Fragment>
            <FormComponent.button label={'Cancel'} handleClick={() => setDeleteDialog(false)}/>
            <FormComponent.button label={'Delete'} handleClick={handleOnDeleteConfirmed} color={'info'}/>
        </React.Fragment>;
    }

    const buildFilterBox = () => {
        return <DepartmentRoomSelectComponent dispatch={dispatch} state={state} setList={setBatchList} type={'inline'}/>
    }

    const buildStatusIcon = (data: any) => data.status === 'active' ? 'checked' : 'close';

    const buildActions = () => {
        if (userInfo === null) return [];
        if (RoleModel.isSuperAdmin(userInfo['role'])) {
            return [{icon: 'edit', event: handleOnEditClick}, {icon: 'delete', event: handleOnDeleteClick}]
        }
        return [];
    }

    const buildStatusDialogContent = () => {
        return <React.Fragment><Typography gutterBottom>Are you sure you want to enable/disable this {state.selected['name']}?</Typography></React.Fragment>;
    }

    const buildStatusDialogActions = () => {
        return <React.Fragment>
            <FormComponent.button label={'Cancel'} handleClick={() => setStatusDialog(false)}/>
            <FormComponent.button label={'Confirm'} handleClick={handleOnStatusConfirmed} color={'info'}/>
        </React.Fragment>;
    }

    const buildStatus = () => {
        const event = RoleModel.isSuperAdmin(userInfo['role']) ? handleOnClickStatus : null;
        return {display: 'Status', type: tableComponentTypes.BUTTONS, components: [{icon: buildStatusIcon, event: event}]};
    }

    return (
        <React.Fragment>
            <BatchInfoDialogComponent params={{
                setBatch: batchInfo,
                strainList: strainList
            }}
                                   dialog={{dialog: batchInfoDialog, setDialog: setBatchInfoDialog}}/>
            <DialogFormComponent params={{content: buildEditDialogContent(), actions: buildEditDialogActions(), title: pageInfo.title, dispatch: dispatch}}
                                 dialog={{dialog: eaDialog, setDialog: setEADialog}}/>
            <DialogFormComponent params={{content: buildDeleteDialogContent(), actions: buildDeleteDialogActions(), title: pageInfo.title, dispatch: dispatch}}
                                 dialog={{dialog: deleteDialog, setDialog: setDeleteDialog}}/>
            <DialogFormComponent params={{content: buildStatusDialogContent(), actions: buildStatusDialogActions(), title: pageInfo.title, dispatch: dispatch}}
                                 dialog={{dialog: statusDialog, setDialog: setStatusDialog}}/>
            <WrapperComponent>
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', height: '812px'}} className={'manage'}>
                    <SimpleTableBoxComponent title={'Batch Log'} handleOnAddClick={handleOnAddClick} addOn={buildFilterBox()} show={RoleModel.isSuperAdmin(userInfo['role'])}/>
                    <SimpleTableComponent params={{
                        headers: [
                            {display: 'Batch Name', type: tableComponentTypes.PLAIN, field: "name", withEdit: RoleModel.isSuperAdmin(userInfo['role']) ? showBatchInfo : null},
                            {display: 'Strain', type: tableComponentTypes.PLAIN, field: "strain", populate: {list: strainList, name: 'name'}},
                            buildStatus(),
                            {display: 'Total Plants', type: tableComponentTypes.PLAIN, field: "totalPlants"},
                            {display: 'Note', type: tableComponentTypes.PLAIN, field: "note"},
                            {display: 'Created', type: tableComponentTypes.PLAIN, field: "dateCreated", format: FormatTime.toDateTime},
                            {
                                display: 'Option', type: tableComponentTypes.BUTTONS, align: tableAlignmentTypes.LEFT, components: buildActions()
                            }],
                        data: batchList,
                    }}/>
                    <SimpleTablePaginateComponent list={batchList} setList={setBatchList} limit={15} onChange={handlePaginateOnChange}/>
                </Box>
            </WrapperComponent>
        </React.Fragment>
    )
}

export default BatchPage;
