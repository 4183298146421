import {
    Box,
    CircularProgress,
    Icon,
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import Format from 'app/utils/Format';
import { debug } from 'console';

export interface TableComponentInterface<T> {
    params: {
        data: T[];
        headers: TableHeaderInterface[];
    };
}

export interface TableHeaderInterface {
    display: string;
    type?: TableComponentTypes;
    align?: TableAlignmentTypes;
    field?: string;
    format?: any;
    populate?: any;
    withEdit?: any;
    components?: TableMaterialInterface[];
}

export interface TableMaterialInterface {
    event: any;
    icon?: any;
    placeholder?: string;
}

export enum TableComponentTypes {
    PLAIN = 'PLAIN',
    BUTTONS = 'BUTTONS',
    SWITCH = 'SWITCH',
}

export enum TableAlignmentTypes {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}

export default function SimpleTableComponent<T>(props: TableComponentInterface<T>) {
    const { headers, data } = props.params;

    const buildHeaders = (): any => {
        const header: JSX.Element[] = [];
        headers.forEach(function (param: any, index) {
            const name = typeof param === 'object' ? param.display : param;
            const _header = (align: any) => {
                // if (name.trim().toLowerCase() === 'option' && param.components.length <= 0)
                //     return header.push(<React.Fragment />);
                header.push(
                    <TableCell
                        key={`${index}-${param}`}
                        align={align}
                        className={name.trim().toLowerCase() == 'option' ? 'option' : ''}
                    >
                        {name}
                    </TableCell>,
                );
            };

            if (name.trim().toLowerCase() === 'option') _header(TableAlignmentTypes.CENTER);
            else _header(index === headers.length - 1 ? TableAlignmentTypes.LEFT : TableAlignmentTypes.LEFT);
        });
        return header;
    };

    const _buildComponentRows = (data: any, param: any) => {
        const components: any = [];

        if (param.type === TableComponentTypes.PLAIN || param.type === TableComponentTypes.SWITCH) {
            const name = param.field !== undefined ? param.field : param;
            let value = data[name];
            const split = name.split('.');
            if (split.length === 2) {
                value = data[split[0]][split[1]];
            }

            if (param.type === TableComponentTypes.SWITCH) {
                param.components?.forEach((f: any) => {
                    components.push(<Switch defaultChecked={value === 'active'} onChange={() => f.event(data)} />);
                });
                return (
                    <TableCell key={name} align={param?.align ? param?.align : TableAlignmentTypes.LEFT}>
                        {components}
                    </TableCell>
                );
            } else {
                if (param.populate !== undefined) {
                    value = Format.populate({ list: param.populate.list, id: value, name: param.populate.name });
                }

                let valueSet = param.format !== undefined ? param.format(value) : value;
                valueSet =
                    param.withEdit !== undefined && param.withEdit !== null ? (
                        <a onClick={() => param.withEdit(data)}>{valueSet}</a>
                    ) : (
                        valueSet
                    );
                return (
                    <TableCell key={name} align={param?.align ? param?.align : TableAlignmentTypes.LEFT}>
                        {valueSet}
                    </TableCell>
                );
            }
        }

        if (param.type === TableComponentTypes.BUTTONS && param.components.length >= 1) {
            param.components?.forEach((f: any, index: any) => {
                const icon = typeof f.icon == 'function' ? f.icon(data) : f.icon;
                const _icon = (
                    <Icon key={index} sx={{ opacity: 0.5, margin: '5px' }}>
                        {icon}
                    </Icon>
                );
                components.push(
                    f.event ? (
                        <IconButton key={index} aria-label={icon} onClick={() => f.event(data)}>
                            <Icon>{icon}</Icon>
                        </IconButton>
                    ) : (
                        _icon
                    ),
                );
            });
            return (
                <TableCell key={Math.random()} align={param?.align ? param?.align : TableAlignmentTypes.LEFT}>
                    {components}
                </TableCell>
            );
        }
    };

    const buildRows = (): any => {
        const rows: any = [];

        const _process = (result: any) => {
            const myCells: any[] = [];

            headers.forEach(function (param: any) {
                myCells.push(_buildComponentRows(result, param));
            });

            return (
                <TableRow key={result.id || Math.random()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {myCells}
                </TableRow>
            );
        };
        if (data !== undefined) {
            data.map((result: any) => rows.push(_process(result)));
        }

        return rows;
    };

    const buildTable = (): any => {
        return data ? (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>{buildHeaders()}</TableRow>
                </TableHead>
                <TableBody>{buildRows()}</TableBody>
            </Table>
        ) : (
            buildNoRecordsFound()
        );
    };

    const buildNoRecordsFound = (): any => {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="70px">
                <CircularProgress />
            </Box>
        );
    };

    return (
        <TableContainer component={Paper} style={{ borderRadius: 10 }}>
            {buildTable()}
        </TableContainer>
    );
}
