import { Card, Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import * as React from 'react';
import { makeStyles } from '@mui/styles';

export const Styles: any = makeStyles((theme) => ({
    option: {
        padding: '1px',
        textAlign: 'right',
        height: '60px',
        borderRadius: 10,
        boxShadow: 'none',
    },
    inline: {
        float: 'left',
        width: '200px',
    },
}));

const SimpleTableBoxComponent = (props: any) => {
    const styles = Styles();
    const { title, handleOnAddClick, addOn, show } = props;

    const buildAction = () => {
        if (show === undefined || !show) return <></>;
        return (
            <Grid item md={6}>
                <IconButton aria-label="add" onClick={handleOnAddClick}>
                    <AddCircleIcon style={{ fontSize: 40 }} />
                </IconButton>
            </Grid>
        );
    };

    return (
        <Box mb={1} mt={3}>
            <Card elevation={1} className={styles.option}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                        <div className={styles.inline}>
                            <Box p={2}>
                                <Typography variant={'subtitle1'} align="left">
                                    <strong>{title}</strong>
                                </Typography>
                            </Box>
                        </div>
                        {addOn}
                    </Grid>
                    {buildAction()}
                </Grid>
            </Card>
        </Box>
    );
};

export default SimpleTableBoxComponent;
