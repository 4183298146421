import Config from "app/configs/Config";
import Model, {Describe} from "./Model";

export default class PageModel extends Model implements Describe {
    config = {
        actions: {
            AllPages: {path: Config.PATH_PAGES},
        }
    };
}
