import React, {useEffect, useReducer} from 'react';
import {Avatar, Box, Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import ButtonComponent from "app/pages/components/ButtonComponent";
import styled from "styled-components";
import {LoginStyle} from "app/pages/login/LoginStyle";
import {useParams} from 'react-router-dom';
import {ResetPasswordDefault, ResetPasswordReducer, ResetPasswordSelector} from "./ResetPasswordReducer";
import {ResetPasswordService} from "app/services/auth/ResetPassword";

export class WidgetStyled {
    static DivProgress = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
    `;
    static DivErrorMessage = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: red;
    `;
}

const ResetPasswordPage = () => {
    const {id, token} = useParams();
    const style = LoginStyle();
    const [state, dispatch] = useReducer(ResetPasswordReducer, ResetPasswordDefault);

    useEffect(() => {
        let isValid;
        console.log(id, token)
        if (id && token) {
            isValid = state.password.trim() === state.rePassword.trim();
        } else {
            isValid = state.email.trim();
        }
        isValid
            ? dispatch({
                type: ResetPasswordSelector.setIsButtonDisabled,
                payload: false,
            })
            : dispatch({
                type: ResetPasswordSelector.setIsButtonDisabled,
                payload: true,
            });
    }, [id && token ? state.email : state.email]);

    const onHandleSubmit = () => {
        const resetPasswordService = new ResetPasswordService();
        // const _failed = () => {
        //     dispatch({
        //         type: LoginSelector.statusFailed,
        //         payload: 'Invalid email',
        //     });
        // };
        //
        // const _success = () => {
        //     window.location.href = RoutePath.LOGIN;
        // };
        // resetPasswordService.requestResetPassword({email: state.email}).then((result: boolean) => {
        //     result ? _success() : _failed();
        // });
    };

    const handleSubmit = () => {
        dispatch({
            type: ResetPasswordSelector.setIsAuthenticating,
            payload: true,
        });
        onHandleSubmit();
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        const {key} = event;
        if (key === 'Enter') {
            state.isButtonDisabled || handleSubmit();
        }
    };

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch({
            type: ResetPasswordSelector.setEmail,
            payload: event.target.value,
        });
    };

    return (
        <form noValidate autoComplete="off" className={style.container}>
            <Card className={style.card} elevation={1}>
                <CardContent>
                    <Box sx={{textAlign: 'center'}}>
                        <Avatar alt="Batch Management" src="/assets/img/logo.png"
                                sx={{backgroundColor: 'grey', margin: 'auto', marginBottom: '9px'}}/>
                        <Typography gutterBottom variant="h5" component="div">
                            Reset Password
                        </Typography>
                    </Box>
                    <Grid pb={2}>
                        <TextField
                            error={state.isError}
                            fullWidth
                            id="email"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            margin="normal"
                            onChange={handleEmailChange}
                            onKeyPress={handleKeyPress}
                        />
                    </Grid>
                    <ButtonComponent fullWidth={true} handleClick={handleSubmit} reducer={state}
                                     label={'Reset Password'}/>
                </CardContent>
            </Card>
        </form>
    );
};

export default ResetPasswordPage;

