import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useReducer, useState} from "react";
import {Styles} from './room/Styles';
import WrapperComponent from "./components/WrapperComponent";
import DialogFormComponent from "./components/DialogFormComponent";
import Model from "app/models/Model";
import {Action, Dispatch, Reducer} from "app/models/Model/State";
import SimpleTableComponent, {TableAlignmentTypes, TableComponentTypes} from "app/pages/components/SimpleTableComponent";
import SimpleTableBoxComponent from "app/pages/components/SimpleTableBoxComponent";
import FormComponent from "./components/FormComponent";
import SimpleTablePaginateComponent from "./components/SimpleTablePaginateComponent";
import PlantModel from "app/models/PlantModel";
import StrainModel from "app/models/StrainModel";
import FormatTime from "app/utils/FormatTime";

const PlantPage = () => {
    const styles = Styles();
    const model = Model.prepare({Plant: new PlantModel(), Strain: new StrainModel()});
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [pageInfo, setPageInfo] = useState({title: ''});
    const [plantList, setPlantList] = useState([]);
    const [strainList, setStrainList] = useState([]);
    const [eaDialog, setEADialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const tableComponentTypes = TableComponentTypes;
    const tableAlignmentTypes = TableAlignmentTypes;

    useEffect(() => {
        model.Plant.fetch("AllPlants").with({set: setPlantList, dispatch: dispatch, params: {skip: 0, limit: 15}});
    }, [])

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({type: Action.setIsWorking, payload: true});

        if (Object.keys(state.selected).length === 0) model.Plant.add(state).with({set: setPlantList, onSet: _onSet});
        else model.Plant.update(state).with({set: setPlantList, onSet: _onSet});
    };

    const handleOnKeyPress = (event: any) => {
        const {key} = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    }

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({type: Action.setData, payload: payload});
    }

    const handleOnEditClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        setPageInfo({title: 'Edit ' + data.name});
        setEADialog(true);
    }

    const handleOnDeleteClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        setPageInfo({title: 'Delete ' + data.name});
        setDeleteDialog(true);
    }

    const handleOnDeleteConfirmed = (event: any) => {
        const _onSet = (payload: any) => setDeleteDialog(false);
        model.Plant.delete(state).with({set: setPlantList, onSet: _onSet});
    }

    const handleOnAddClick = (event: any) => {
        dispatch({type: Action.setReset, payload: null});
        setPageInfo({title: 'Add Plant'});
        setEADialog(true);
    }

    const handleOnClickStatus = (data: any) => {
        data.status = data.status == 'active' ? 'inActive' : 'active';
        dispatch({type: Action.setSingleUpdate, payload: data});
        model.Plant.update(state).with({set: setPlantList});
    }

    const buildEditDialogContent = () => {
        return <Box mb={2}>
            <FormComponent.textField state={state} label={'Name'} id={"name"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.textField state={state} label={'Code'} id={"code"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
        </Box>;
    }

    const buildEditDialogActions = () => {
        return <FormComponent.button label={'Save'} handleClick={handleOnSubmit} color={'info'}/>
    }

    const buildDeleteDialogContent = () => {
        return <React.Fragment><Typography gutterBottom>Are you sure you want to delete this {state.selected['name']}?</Typography></React.Fragment>;
    }

    const buildDeleteDialogActions = () => {
        return <React.Fragment>
            <FormComponent.button label={'Cancel'} handleClick={() => setDeleteDialog(false)}/>
            <FormComponent.button label={'Delete'} handleClick={handleOnDeleteConfirmed} color={'info'}/>
        </React.Fragment>;
    }

    return (
        <React.Fragment>
            <DialogFormComponent params={{content: buildEditDialogContent(), actions: buildEditDialogActions(), title: pageInfo.title}}
                                 dialog={{dialog: eaDialog, setDialog: setEADialog}}/>
            <DialogFormComponent params={{content: buildDeleteDialogContent(), actions: buildDeleteDialogActions(), title: pageInfo.title}}
                                 dialog={{dialog: deleteDialog, setDialog: setDeleteDialog}}/>
            <WrapperComponent>
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', height: '612px'}} className={'manage'}>
                    <SimpleTableBoxComponent title={'Manage Plants'} handleOnAddClick={handleOnAddClick}/>
                    <SimpleTableComponent params={{
                        headers: [
                            {display: 'Plant Name', type: tableComponentTypes.PLAIN, field: "name", withEdit: handleOnEditClick},
                            {display: 'Code', type: tableComponentTypes.PLAIN, field: "code"},
                            {display: 'Created', type: tableComponentTypes.PLAIN, field: "dateCreated", format: FormatTime.toDateTime},
                            {
                                display: 'Option', type: tableComponentTypes.BUTTONS, align: tableAlignmentTypes.LEFT, components: [{icon: 'edit', event: handleOnEditClick},
                                    {icon: 'delete', event: handleOnDeleteClick}]
                            }],
                        data: plantList,
                    }}/>
                    <SimpleTablePaginateComponent/>
                </Box>
            </WrapperComponent>
        </React.Fragment>
    )
}

export default PlantPage;
