import {Button, CircularProgress} from '@mui/material';
import React from 'react';
import styled from "styled-components";

export class WidgetStyled {
    static DivProgress = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
    `;
    static DivErrorMessage = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: red;
    `;
}

const ButtonComponent = (props: any) => {
    const button = (
        <Button
            fullWidth={props.fullWidth}
            onClick={props.handleClick}
            variant="contained"
            size="large"
            color="primary"
            disabled={props.reducer.isButtonDisabled}>
            {props.label}
        </Button>
    );
    const loader = (
        <>  <CircularProgress/>
            <WidgetStyled.DivProgress>Please wait...</WidgetStyled.DivProgress>
        </>
    );
    return props.isStatus ? loader : button;
};

export default ButtonComponent;
