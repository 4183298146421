import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Fab, Typography } from '@mui/material';
import { Action } from 'app/models/Model/State';
import DialogFormComponent from './DialogFormComponent';
import FormComponent from './FormComponent';
import Format from 'app/utils/Format';
import RoomBatchSelectComponent from './RoomBatchSelectComponent';
import BuildActionDefinitionTypeDialog from './BuildActionDefinitionTypeDialog';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import SimpleTableComponent, { TableComponentTypes } from './SimpleTableComponent';
import FormatTime from '../../utils/FormatTime';
import SimpleTablePaginateComponent from './SimpleTablePaginateComponent';
import QRCode from 'react-qr-code';
import { Styles } from '../room/Styles';
import Model from '../../models/Model';
import UserModel from '../../models/UserModel';
import RoomModel from '../../models/RoomModel';
import BatchModel from '../../models/BatchModel';
import DepartmentModel from '../../models/DepartmentModel';
import ActionDefinitionModel from '../../models/ActionDefinitionModel';
import ActionModel from '../../models/ActionModel';

const BatchInfoDialogComponent = (props: any) => {
    const { setBatch, strainList } = props.params;
    const { dialog, setDialog } = props.dialog;

    const model = Model.prepare({
        Action: new ActionModel(),
    });
    const [actionList, setActionList] = useState([]);
    const styles = Styles();
    const tableComponentTypes = TableComponentTypes;

    useEffect(() => {
        if (setBatch.id) {
            model.Action.fetch('ByBatch').with({ set: setActionList, params: { id: setBatch.id, skip: 0, limit: 15 } });
        }
    }, [setBatch]);

    const handleClose = () => {
        setDialog(false);
    };

    const buildBatchActions = () => {
        return (
            <React.Fragment>
                <Button onClick={handleClose} variant="contained" size="large" color="primary">
                    Close
                </Button>
            </React.Fragment>
        );
    };

    const handleOnCloseDialog = () => {
        setDialog(false);
    };

    const getStrain = () => {
        if (setBatch && strainList?.length > 0) {
            const strain: any = strainList?.find((f: any) => f._id === (setBatch as any)?.strain);
            if (strain) {
                return strain?.name;
            } else {
                return 'Unknown';
            }
        }
    };

    const buildBatchContent = () => {
        return (
            <Grid item xs={12} sm={12} md={12} sx={{ width: '800px' }}>
                <Grid container spacing={0}>
                    <Grid item xs={10} sm={10} md={10}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {setBatch['name'] ?? ''}
                        </Typography>
                        <Typography>Strain: {getStrain()}</Typography>
                        <Typography>Plants: Unknown</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                        <div className={styles.qr}>
                            <QRCode value={setBatch['id']} size={100} />{' '}
                        </div>
                    </Grid>
                </Grid>
                <Box pt={12} sx={{ position: 'relative' }}>
                    <SimpleTableComponent
                        params={{
                            headers: [
                                { display: 'Action', type: tableComponentTypes.PLAIN, field: 'description' },
                                {
                                    display: 'Owner',
                                    type: tableComponentTypes.PLAIN,
                                    field: 'user.name',
                                },
                                { display: 'Info', type: tableComponentTypes.PLAIN, field: 'note' },
                                {
                                    display: 'Date',
                                    type: tableComponentTypes.PLAIN,
                                    field: 'dateCreated',
                                    format: FormatTime.toDate,
                                },
                            ],
                            data: actionList,
                        }}
                    />
                    <SimpleTablePaginateComponent />
                </Box>
            </Grid>
        );
    };

    return (
        <DialogFormComponent
            params={{
                content: buildBatchContent(),
                actions: buildBatchActions(),
                title: 'Batch Info',
                onClose: handleOnCloseDialog,
            }}
            dialog={{ dialog: dialog, setDialog: setDialog }}
        />
    );
};

export default BatchInfoDialogComponent;
