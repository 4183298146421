import axios, {AxiosError} from 'axios';
import Config from "app/configs/Config";
import {AuthResult} from "app/services/auth/AuthService";
import ApiInterceptor from 'app/services/interceptor/ApiInterceptor';

export const AxiosInstance = axios.create({baseURL: Config.REACT_APP_API_BASE_URL});

export enum ApiMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export type ApiData = {
    data?: any;
    status?: any;
    error?: any;
};

export class ApiService {
    static request = async (args: {
        method: ApiMethod | any;
        url: string | any;
        data?: any
        params?: any;
    }): Promise<any> => {
        return ApiInterceptor().then(() => {
            return ConnectionService.connect({
                url: args.url,
                method: args.method,
                data: args.data,
                params: args.params
            })
                .then((response) => {
                    const apiData: ApiData = {
                        data: response.data,
                        status: {code: response.status, text: response.statusText},
                    };
                    return apiData;
                })
                .catch(function (error: any) {
                    const axiosError = error as AxiosError;
                    const authResult: AuthResult = {error: {code: axiosError.code, name: axiosError.name}, type: false};
                    return authResult;
                });
        });

    };
}


export class ConnectionService {
    static connect = async (args: {
        method: ApiMethod | any;
        url: string;
        data?: any;
        params?: any;
    }): Promise<any> => {
        const params = {...{crossDomain: true}, ...args.params}
        return AxiosInstance.request({
            method: args.method,
            url: args.url,
            data: args.data,
            params: params,
        })
            .then((response) => {

                const apiData: ApiData = {
                    data: response.data,
                    status: {code: response.status, text: response.statusText},
                };
                return apiData;
            })
            .catch(function (error: any) {
                const axiosError = error as AxiosError;
                const apiData: ApiData = {status: axiosError.response?.status};
                return apiData;
            });
    };
}
