import React, { useEffect, useState } from 'react';
import RoomModel from 'app/models/RoomModel';
import BatchModel from 'app/models/BatchModel';
import Model from 'app/models/Model';
import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ActionModel from 'app/models/ActionModel';
import DepartmentModel from 'app/models/DepartmentModel';
import { Action } from 'app/models/Model/State';

export const Styles: any = makeStyles((theme) => ({
    options: {
        padding: '20px',
    },
    select: {
        fontSize: '12px',
    },
    inline: {
        float: 'left',
        width: '150px',
        paddingRight: '5px',
    },
}));

class Cache {
    static departmentList: any = undefined;
    static roomList: any = undefined;
}

export default function RoomBatchSelectComponent(props: any) {
    const styles = Styles();
    const { state, type, dispatcher, handleOnChange, setDepartment, setRoom, setBatch, noBatch } = props;
    const model = Model.prepare({
        Room: new RoomModel(),
        Batch: new BatchModel(),
        Action: new ActionModel(),
        Department: new DepartmentModel(),
    });
    const [departmentList, setDepartmentList] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [actionList, setActionList] = useState(0);
    const [selectedDepartment, setSelectedDepartment] = useState(setDepartment ?? '');
    const [selectedRoom, setSelectedRoom] = useState(setRoom ?? '');
    const [selectedBatch, setSelectedBatch] = useState(setBatch ?? '');
    const actions: any = [];

    useEffect(() => {
        if (Cache.departmentList === undefined) {
            model.Department.fetch('AllDepartments').with({ set: setDepartmentList, onSet: onSetDepartment });
        } else {
            setDepartmentList(Cache.departmentList);
            onSetDepartment(Cache.departmentList);
        }

        if (props.setDepartment && props.setDepartment !== '') {
            model.Room.fetch('ByDepartment').with({
                set: setRoomList,
                onSet: onSetRoom,
                params: { id: setDepartment, skip: 0, limit: 15 },
            });
        }
    }, []);

    const onSetAction = (payload: any) => {
        if (type == 'inline' && payload) props.set(payload);
    };

    const onSetDepartment = (payload: any) => {
        if (payload && payload?.length > 0) {
            Cache.departmentList = payload;
            const departmentId = setDepartment ? setDepartment : payload ? payload[0]['id'] : null;
            if (state.selected['department'] == undefined && dispatcher != undefined) {
                dispatcher({ type: Action.setData, payload: { department: departmentId } });
            }

            const event = { target: { value: state.filter.departmentId } };
            onChangeDepartment(event).then();
        }
    };

    const onSetRoom = (payload: any) => {
        if (payload && payload?.length > 0) {
            Cache.roomList = payload;
            const roomId = setRoom ? setRoom : payload ? payload[0]['id'] : null;
            const list: any = {};
            if (payload == undefined) return;
            if (state.selected['room'] == undefined && dispatcher != undefined) {
                dispatcher({ type: Action.setData, payload: { room: roomId } });
            }
            payload.forEach((result: any) => {
                list[result.id] = result.batches;
            });
            setBatchList(list);
            const getBatches = list[roomId];
            const batchId = setBatch ? setBatch : getBatches?.length > 0 ? getBatches[0]['id'] : null;
            if (state.selected['batch'] == undefined && dispatcher != undefined) {
                dispatcher({ type: Action.setData, payload: { batch: batchId } });
            }
        }
    };

    const onChangeDepartment = async (event: any) => {
        const departmentId = event.target.value;
        if (!departmentId) {
            return;
        }

        if (handleOnChange != null) {
            handleOnChange(event);
        }

        Cache.roomList = undefined;

        setSelectedDepartment(departmentId);
        setSelectedRoom(state.selected['room'] || '');
        setSelectedBatch(state.selected['batch'] || '');

        if (Cache.roomList === undefined && state.filter !== departmentId) {
            model.Room.fetch('ByDepartment').with({
                set: setRoomList,
                onSet: onSetRoom,
                params: { id: departmentId, skip: 0, limit: 15 },
            });
        }
        if (type == 'inline')
            await model.Action.fetch('ByDepartment').with({
                set: setActionList,
                onSet: onSetAction,
                params: { id: departmentId, skip: 0, limit: 15 },
            });
    };

    const onChangeRoom = async (event: any) => {
        const roomId = event.target.value;
        if (handleOnChange != null) handleOnChange(event);
        setSelectedRoom(roomId);
        await model.Action.fetch('ByRoom').with({
            set: setActionList,
            onSet: onSetAction,
            params: { id: roomId, skip: 0, limit: 15 },
        });
    };

    const onChangeBatch = async (event: any) => {
        const batchId = event.target.value;
        if (handleOnChange != null) handleOnChange(event);
        setSelectedBatch(batchId);
        await model.Action.fetch('ByBatch').with({
            set: setActionList,
            onSet: onSetAction,
            params: { id: batchId, skip: 0, limit: 15 },
        });
    };

    const buildDepartmentOptions = () => {
        const options: any = [];
        departmentList.forEach((option: any) => {
            options.push(
                <option key={option.id} value={option.id} className={styles.options}>
                    {option.name}
                </option>,
            );
        });
        return options;
    };

    const buildRoomOptions = (selected: any) => {
        const options: any = [];
        if (Cache.roomList === undefined) return;

        Cache.roomList.forEach((option: any) => {
            options.push(
                <option key={option.id} value={option.id} className={styles.options}>
                    {option.name}
                </option>,
            );
            actions[option.id] = option.actions;
        });
        return options;
    };

    const buildBatchOptions = () => {
        const options: any = [];
        const selected: any = selectedRoom;
        const list: any = batchList[selected];
        if (list === undefined) return;

        list.forEach((option: any) => {
            options.push(
                <option key={option.id} value={option.id} className={styles.options}>
                    {option.name}
                </option>,
            );
        });
        return options;
    };

    const Wrap = (content: any) => {
        return type == 'inline' ? (
            <div className={styles.inline}>{content.children}</div>
        ) : (
            <Box mt={1.5}>{content.children}</Box>
        );
    };

    const buildDepartment = () => {
        const selected = selectedDepartment || state.filter.departmentId;
        return (
            <Wrap>
                <TextField
                    select
                    fullWidth
                    id={'department'}
                    label={type == 'inline' ? '' : ''}
                    value={selected}
                    onChange={onChangeDepartment}
                    SelectProps={{ native: true }}
                    className={styles.select}
                >
                    <option key={'all'} value={''} className={styles.options}>
                        {type == 'inline' ? 'All Departments' : 'Select Department'}
                    </option>
                    {buildDepartmentOptions()}
                </TextField>
            </Wrap>
        );
    };

    const buildRoom = () => {
        if (roomList === undefined) return <div />;
        if (roomList.length <= 0) return <div />;
        const selected = selectedRoom || state.selected['room'];
        return (
            <Wrap>
                <TextField
                    select
                    fullWidth
                    id={'room'}
                    label={type == 'inline' ? '' : ''}
                    value={selected}
                    onChange={onChangeRoom}
                    SelectProps={{ native: true }}
                >
                    <option key={'all'} value={''} className={styles.options}>
                        Select Room
                    </option>
                    {buildRoomOptions(selected)}
                </TextField>
            </Wrap>
        );
    };

    const buildBatch = () => {
        if (selectedRoom == '' || (noBatch !== undefined && noBatch == true)) return <div />;
        const selected = selectedBatch !== null ? selectedBatch : state.selected['batch'];
        return (
            <Wrap>
                <TextField
                    select
                    fullWidth
                    id={'batch'}
                    label={type == 'inline' ? '' : ''}
                    value={selected}
                    onChange={onChangeBatch}
                    SelectProps={{ native: true }}
                >
                    <option key={'all'} value={''} className={styles.options}>
                        Select Batch
                    </option>
                    {buildBatchOptions()}
                </TextField>
            </Wrap>
        );
    };
    return (
        <>
            {buildDepartment()}
            {buildRoom()}
            {buildBatch()}
        </>
    );
}
