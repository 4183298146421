import Model, {Describe} from "./Model";
import Config from "app/configs/Config";

export default class FarmModel extends Model implements Describe {
    config = {
        actions: {
            AllFarms: {path: Config.PATH_FETCH_FARM},
            SaveData: {path: Config.PATH_FETCH_FARM},
            UpdateData: {path: Config.PATH_FETCH_FARM},
            DeleteData: {path: Config.PATH_FETCH_FARM},
        }
    }

}

