import Box from "@mui/material/Box";
import * as React from "react";

const WrapperComponent = (props: any) => {
    return <Box
        component="main"
        sx={{
            position: 'relative',
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
        }}
    >
        {props.children}
    </Box>
}
export default WrapperComponent;
