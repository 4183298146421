import {AxiosError} from 'axios';
import Config from 'app/configs/Config';
import UserModel from 'app/models/UserModel';
import {ApiMethod, ConnectionService} from 'app/services/api/ApiService';

import {Store} from "app/stores/Store";
import AuthInterceptor from "app/services/interceptor/AuthInterceptor";

export type AuthResult = {
    result?: any;
    type?: boolean;
    error?: any;
};

export class AuthService {
    userModel = new UserModel(Store);

    constructor() {
        AuthInterceptor();
    }

    login = async (args: { email: string; password: string }): Promise<AuthResult> => {
        const data: any = {
            email: args.email,
            password: args.password,
            crossDomain: true,
        };
        return await ConnectionService.connect({
            url: Config.PATH_LOGIN,
            method: ApiMethod.POST,
            data: data
        })
            .then((result) => {
                const hasToken = result.data.token !== undefined;
                if (hasToken) {
                    this.userModel.writeData(result.data);
                }
                const authResult: AuthResult = {type: hasToken};
                return authResult;
            })
            .catch(function (error: any) {
                const axiosError = error as AxiosError;
                const authResult: AuthResult = {error: {code: axiosError.code, name: axiosError.name}, type: false};
                return authResult;
            });
    };

}
