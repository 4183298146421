import {StorageService} from 'app/services/storage/StorageService';
import Model, {Constructor, Describe, Setter} from "./Model";
import Config from "app/configs/Config";

export default class UserModel extends Model implements Describe {
    name = '';
    config = {
        actions: {
            AllUsers: {path: Config.PATH_USER},
            ById: {path: Config.PATH_USER},
            SaveData: {path: Config.PATH_USER},
            UpdateData: {path: Config.PATH_USER},
            DeleteData: {path: Config.PATH_USER},
        }
    }

    fetch(name: string) {
        if (name != 'Current') super.fetch(name);
        this.name = name;
        return this;
    }

    save = (data: any) => StorageService.write(data);

    with<T extends Constructor>(setter: Setter) {
        if (this.name != 'Current') return super.with<Constructor>(setter);

        StorageService.read().then((data) => {
            setter.set(data.user);
            if (setter.onSet) setter.onSet(data.user);
            else setter.set(data.user);
        })
    }

    logout = () => {
        StorageService.delete();
    }
    writeData = (data: any) => {
        StorageService.write(data);
    }
}

