import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Fab,
    List,
    ListItemAvatar,
    Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import SimpleTableComponent, { TableComponentTypes } from 'app/pages/components/SimpleTableComponent';
import FolderIcon from '@mui/icons-material/Folder';
import FormatTime from 'app/utils/FormatTime';
import QRCode from 'react-qr-code';
import { RoutePath } from 'app/routes/Route';
import { Styles } from './room/Styles';
import Model from 'app/models/Model';
import RoomModel from 'app/models/RoomModel';
import WrapperComponent from './components/WrapperComponent';
import SimpleTablePaginateComponent from './components/SimpleTablePaginateComponent';
import BatchModel from 'app/models/BatchModel';
import ActionModel from 'app/models/ActionModel';
import { useNavigate } from 'react-router-dom';
import { Action, Dispatch, Reducer } from '../models/Model/State';
import AddIcon from '@mui/icons-material/Add';
import BuildActionsComponent from './components/BuildActionsComponent';
import ActionDefinitionModel from '../models/ActionDefinitionModel';
import DepartmentModel from '../models/DepartmentModel';
import UserModel from '../models/UserModel';
import StrainModel from '../models/StrainModel';
import PlantModel from '../models/PlantModel';

const TypePage = () => {
    const styles = Styles();
    const navigate = useNavigate();
    const model = Model.prepare({
        User: new UserModel(),
        Room: new RoomModel(),
        Batch: new BatchModel(),
        Department: new DepartmentModel(),
        ActionDefinition: new ActionDefinitionModel(),
        Action: new ActionModel(),
        Strain: new StrainModel(),
        Plant: new PlantModel(),
    });
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [roomList, setRoomList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [actionList, setActionList] = useState([]);
    const [actionDefinitionList, setActionDefinitionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [strainList, setStrainList] = useState([]);
    const [batchData, setBatchData] = useState({ name: '', id: '' });
    const [selectedBatch, setSelectedBatch] = useState({});
    const [roomData, setRoomData] = useState({ id: '' });
    const [userData, setUserData] = useState([]);
    const [plantList, setPlantList] = useState([]);
    const [getDepartmentData, setDepartment] = useState('');
    const [getRoomData, setRoom] = useState('');
    const [getBatchData, setBatch] = useState('');
    const [isMaximize, setIsMaximize] = useState(false);
    const [isShowCard, setIsShowCard] = useState({ batch: false, info: false });
    const [eaDialog, setEADialog] = useState(false);
    const [isRoomSelected, setIsRoomSelected] = useState(false);
    const roomType = window.location.pathname.split('/')[2];
    const tableComponentTypes: typeof TableComponentTypes = TableComponentTypes;

    useEffect(() => {
        model.User.fetch('Current').with({ set: setUserData });
        model.Room.fetch('ByRoomType').with({
            set: setRoomList,
            onSet: onSetRoom,
            params: { any: roomType, limit: 15 },
        });
        model.Department.fetch('AllDepartments').with({ set: setDepartmentList });
        model.ActionDefinition.fetch('AllActionDefinitions').with({
            set: setActionDefinitionList,
            params: { skip: 0, limit: 15 },
        });
        model.Strain.fetch('AllStrains').with({ set: setStrainList });
        model.Plant.fetch('AllPlants').with({ set: setPlantList, params: { skip: 0, limit: 100 } });
    }, []);

    const onSetBatch = (payload: any) => {
        setBatchList(payload);
        setBatchData({ name: '', id: '' });
        setIsShowCard({ batch: true, info: false });
        handleOnClickBatch(payload[0]);
    };

    const handleOnClickRoom = (roomId: string) => {
        setRoomData({ id: roomId });
        setRoom(roomId);
        const findRoom: any = roomList.find((f: any) => f.id === roomId);
        setDepartment(findRoom?.department);
        model.Batch.fetch('ByRoom').with({
            set: setBatchList,
            onSet: onSetBatch,
            params: { id: roomId, skip: 0, limit: 15 },
        });
    };

    const onSetRoom = (payload: any) => {
        if (payload?.length > 0) {
            handleOnClickRoom(payload[0].id);
            setDepartment(payload[0].department);
        }
    };

    const onSetAction = (payload: any) => {
        setActionList(payload);
        setIsShowCard({ batch: true, info: true });
    };

    const handleOnClickBatch = (result: any) => {
        if (!result) {
            return;
        }

        setBatchData(result);
        setSelectedBatch(result);
        setBatch(result.id);
        model.Action.fetch('ByBatch').with({
            set: setActionList,
            onSet: onSetAction,
            dispatch: dispatch,
            params: { id: result.id, skip: 0, limit: 15 },
        });
    };

    const handleClassNameMaximize = () => (isMaximize ? 'hidden' : '');
    const handleGridMaximize = () => (isMaximize ? 12 : 6);
    const handleOnClickMaximize = () => setIsMaximize(!isMaximize);
    const buildBatchMenus = () => {
        const batchMenu: any = [];
        batchList.forEach((result) => {
            const id = result['id'];
            batchMenu.push(
                <div>
                    <ListItem button key={id} onClick={() => handleOnClickBatch(result)} selected={batchData.id == id}>
                        <ListItemText primary={result['name']} className={styles.menu} />
                    </ListItem>
                    <Divider />
                </div>,
            );
        });
        return batchMenu;
    };

    const buildRoomMenus = () => {
        const roomMenu: any = [];
        roomList.forEach((result) => {
            const id = result['id'];
            roomMenu.push(
                <ListItem button key={id} onClick={() => handleOnClickRoom(id)} selected={roomData.id == id}>
                    <ListItemAvatar>
                        <Avatar className={styles.menuIcon}>
                            {' '}
                            <FolderIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={result['name']} className={styles.menu} />
                </ListItem>,
            );
        });
        return roomMenu;
    };

    const buildMenu = (args: {
        title: string;
        description: string;
        content: any;
        isShow: boolean;
        isSelected: boolean;
    }) => {
        if (!args.isShow) return <div />;

        return (
            <Grid item xs={12} sm={6} md={3} className={handleClassNameMaximize()}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1, position: 'relative' }}>
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            sx={{ position: 'absolute', right: 10, top: 10 }}
                            onClick={(e) => {
                                handleOnAddClick(e, args.isSelected);
                            }}
                        >
                            <AddIcon />
                        </Fab>
                        <Typography gutterBottom variant="h5" component="h2">
                            {args.title}
                        </Typography>
                        <Typography className={styles.describe}>{args.description}</Typography>
                        <Divider sx={{ marginTop: '5px' }} />
                        <List>{args.content()}</List>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    const buildMaximizeOption = () => {
        const full = (
            <a href="#">
                <OpenInFullIcon sx={{ fontSize: 30 }} onClick={() => handleOnClickMaximize()} />
            </a>
        );
        const closeFull = (
            <a href="#">
                <CloseFullscreenIcon sx={{ fontSize: 30 }} onClick={() => handleOnClickMaximize()} />
            </a>
        );
        return isMaximize ? closeFull : full;
    };

    const buildQRCode = (args: { isShow: boolean }) => {
        return (
            <Grid item xs={1} sm={1} md={1}>
                <div className={styles.qr}>
                    {!args.isShow ? <div /> : <QRCode value={batchData['id']} size={100} />}{' '}
                </div>
            </Grid>
        );
    };

    const buildViewBatch = () => {
        return <a onClick={() => navigate(RoutePath.BATCH)}>View Tables</a>;
    };

    const getStrain = () => {
        if (selectedBatch) {
            const strain: any = strainList?.find((f: any) => f._id === (selectedBatch as any)?.strain);
            return strain.name;
        }
    };

    const buildBatchInfo = (args: { isShow: boolean }) => {
        if (!args.isShow) return <div />;
        return (
            <Grid item xs={12} sm={6} md={handleGridMaximize()}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1, position: 'relative' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={10} sm={10} md={10}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {batchData['name'] ?? ''}
                                </Typography>
                                <Typography>Strain: {getStrain()}</Typography>
                                <Typography>Plants: Unknown</Typography>
                            </Grid>
                            {buildQRCode({ isShow: isMaximize })}
                            <Grid item xs={1} sm={1} md={1}>
                                <div className={styles.maximize}>{buildMaximizeOption()}</div>
                            </Grid>
                        </Grid>
                        <Box pt={6} sx={{ position: 'relative' }}>
                            <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                sx={{ position: 'absolute', right: 10, top: 0 }}
                                onClick={(e) => {
                                    handleOnAddClick(e, false);
                                }}
                            >
                                <AddIcon />
                            </Fab>
                            <SimpleTableComponent
                                params={{
                                    headers: [
                                        { display: 'Action', type: tableComponentTypes.PLAIN, field: 'description' },
                                        {
                                            display: 'Owner',
                                            type: tableComponentTypes.PLAIN,
                                            field: 'user.name',
                                        },
                                        { display: 'Info', type: tableComponentTypes.PLAIN, field: 'note' },
                                        {
                                            display: 'Date',
                                            type: tableComponentTypes.PLAIN,
                                            field: 'dateCreated',
                                            format: FormatTime.toDate,
                                        },
                                    ],
                                    data: actionList?.map((m: any) => {
                                        if (typeof m?.user === 'string' && (userData as any)?._id === m.user) {
                                            m.user = userData;
                                            return m;
                                        }
                                        return m;
                                    }),
                                }}
                            />
                            <SimpleTablePaginateComponent />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button size="small">{buildViewBatch()}</Button>
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({ type: Action.setIsWorking, payload: true });
        if (state.pageInfo['action'] === 'add') model.Action.add(state).with({ set: setActionList, onSet: _onSet });
        else model.Action.update(state).with({ set: setActionList, onSet: _onSet });
    };

    const handleOnAddClick = (event: any, isSelected: boolean) => {
        if (actionDefinitionList?.length > 0) {
            setIsRoomSelected(isSelected);
            setEADialog(true);
            dispatch({ type: Action.setData, payload: { description: actionDefinitionList[0]['description'] } });
            dispatch({ type: Action.setPageInfo, payload: { title: 'Add Action', action: 'add' } });
        }
    };

    return (
        <React.Fragment>
            <BuildActionsComponent
                params={{
                    actionDefinitionList: actionDefinitionList,
                    setActionList: setActionList,
                    state: state,
                    dispatcher: dispatch,
                    setDepartment: getDepartmentData,
                    setRoom: getRoomData,
                    setBatch: !isRoomSelected ? getBatchData : null,
                }}
                dialog={{ dialog: eaDialog, setDialog: setEADialog }}
                handleOnSubmit={handleOnSubmit}
            />
            <WrapperComponent>
                <Container sx={{ py: 4 }} maxWidth="xl">
                    <Grid container spacing={2} sx={{ mb: 10 }}>
                        {buildMenu({
                            title: roomType,
                            description: 'There are ' + roomList.length + ' rooms under ' + roomType + ' Type',
                            content: buildRoomMenus,
                            isShow: true,
                            isSelected: true,
                        })}
                        {buildMenu({
                            title: 'Batches',
                            description: 'List of batches under the selected room',
                            content: buildBatchMenus,
                            isShow: isShowCard.batch,
                            isSelected: false,
                        })}
                        {buildBatchInfo({ isShow: isShowCard.info })}
                    </Grid>
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'fixed', right: 75, bottom: 20 }}
                        onClick={(e) => {
                            handleOnAddClick(e, false);
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </Container>
            </WrapperComponent>
        </React.Fragment>
    );
};

export default TypePage;
