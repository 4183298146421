import React, {useEffect, useReducer, useState} from 'react';
import {Avatar, Box, Button, Card, Container, Grid, styled, TextField, Toolbar} from "@mui/material";
import {Styles} from "./dashboard/Styles";
import WrapperComponent from "./components/WrapperComponent";
import DialogFormComponent from "./components/DialogFormComponent";
import {Action, Dispatch, Reducer} from "app/models/Model/State";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Model from "app/models/Model";
import UserModel from "app/models/UserModel";
import FarmModel from "app/models/FarmModel";
import RoleModel from "app/models/RoleModel";
import FormComponent from "./components/FormComponent";
import Format from "app/utils/Format";

const RootStyle = styled(Card)(({theme}) => ({
    'margin-top': '32px',
    width: '100%',
    boxShadow: 'none',
    textAlign: 'center',
    marginBottom: '10px',
    padding: theme.spacing(1, 0),
}));

const HeaderStyle = styled(Card)(({theme}) => ({
    width: '100%',
    padding: '20px',
    boxShadow: 'none',
}));

const FarmPage = () => {
    const styles = Styles();
    const model = Model.prepare({User: new UserModel(), Farm: new FarmModel()});
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [userData, setUserData] = useState({name: '', email: '', role: '', phone: '', photo: ''});
    const [userInfo, setUserInfo] = useState(userData);
    const [farmList, setFarmList] = useState([]);
    const [editDialog, setEditDialog] = useState(false);

    useEffect(() => {
        model.User.fetch("Current").with({set: setUserInfo, onSet: setUser});
        model.Farm.fetch("AllFarms").with({set: setFarmList, params: {id: 'all'}});
    }, []);

    const setUser = (payload: any) => {
        model.User.fetch("ById").with({set: setUserData, dispatch: dispatch, params: {id: payload._id}});
    }

    const handleOnKeyPress = (event: any) => {
        const {key} = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    }

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({type: Action.setData, payload: payload});
    }

    const _buildEditDialogContent = () => {
        return <React.Fragment>
            <TextField fullWidth label="Name" id="name" value={state.data['name'] || state.selected['name']}
                       onChange={handleOnChange} onKeyPress={handleOnKeyPress} margin="normal"/>
            <TextField fullWidth label="Email" id="email" value={state.data['email'] || state.selected['email']}
                       onChange={handleOnChange} margin="normal"/>
            <FormComponent.selectField state={state} label={'Farm'} id={"farm"} handleOnChange={handleOnChange} options={Format.toSelectOptions({list: farmList, name: 'name'})}/>
            <FormComponent.selectField state={state} label={'Role'} id={"role"} handleOnChange={handleOnChange} options={RoleModel.getRole()}/>
            <TextField fullWidth label="Phone" id="phone" value={state.data['phone'] || state.selected['phone']}
                       onChange={handleOnChange} margin="normal"/>
        </React.Fragment>;
    }

    const _buildEditDialogActions = () => {
        return <React.Fragment><Button onClick={handleOnSubmit} variant="contained" size="large" color="primary">Save</Button></React.Fragment>;
    }

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            const data = {...payload[0], ...state.data};
            setUserData(data);
            setEditDialog(false);
        };
        dispatch({type: Action.setIsWorking, payload: true});
        model.User.update(state).with({set: setUserData, onSet: _onSet})
    };

    const editProfile = () => {
        dispatch({type: Action.setSelected, payload: userData});
        setEditDialog(true);
    }

    return (
        <React.Fragment>
            <DialogFormComponent
                params={{content: _buildEditDialogContent(), actions: _buildEditDialogActions(), title: 'Edit'}}
                dialog={{dialog: editDialog, setDialog: setEditDialog}}/>
            <WrapperComponent>
                <Toolbar/>
                <Container fixed sx={{mt: 6, mb: 4}} maxWidth={false}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                        <Grid container md={5} xs={5}>
                            <HeaderStyle>
                                <Container>
                                    <Box alignItems="center"> <Avatar alt={userData.name} src={userData.photo} sx={{width: 86, height: 86}}/></Box>
                                    <Box sx={{typography: 'body1', fontSize: 'h6.fontSize', fontWeight: 'medium'}}> Israel Farm</Box>
                                    <Box sx={{typography: 'body1'}}>israel@farm.com</Box>
                                    <Box>
                                        <Button onClick={editProfile}
                                                sx={{display: 'flex', height: 'fit-content', marginLeft: 'auto'}}
                                                variant="contained" startIcon={<AddCircleOutlineIcon/>}>
                                            Update
                                        </Button>
                                    </Box>
                                </Container>
                                <Container>
                                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Box sx={{fontWeight: 'medium'}}>Phone:</Box>
                                        <Box sx={{typography: 'body1'}} ml={1}>{userData?.phone}</Box>
                                    </Box>
                                </Container>
                            </HeaderStyle>
                        </Grid>
                    </Grid>
                </Container>
            </WrapperComponent>
        </React.Fragment>
    )
}
export default FarmPage;
