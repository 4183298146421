import { Navigate, useRoutes } from 'react-router-dom';
import LoginPage from 'app/pages/login/LoginPage';
import ProfilePage from 'app/pages/ProfilePage';
import FarmPage from 'app/pages/FarmPage';
import AuthLayout from 'app/layouts/AuthLayout';
import BaseLayout from 'app/layouts/BaseLayout';
import NewUserManagePage from 'app/pages/UserPage';
import NewDashboardPage from 'app/pages/DashboardPage';
import NewDepartmentPage from 'app/pages/DepartmentPage';
import NewRoomPage from 'app/pages/RoomPage';
import NewRoomTypePage from 'app/pages/RoomTypePage';
import NewBatchPage from 'app/pages/BatchPage';
import NewPlantPage from 'app/pages/PlantPage';
import NewStrainPage from 'app/pages/StrainPage';
import NewActionPage from 'app/pages/ActionPage';
import NewActionDefinitionPage from 'app/pages/ActionDefinitionPage';
import NewTypePage from 'app/pages/TypePage';
import ResetPasswordPage from 'app/pages/reset-password/ResetPasswordPage';
import SupplierPage from 'app/pages/SupplierPage';

export class RoutePath {
    static ROOT = '';
    static USER = '/user';
    static SUPPLIER = '/supplier';
    static LOGIN = '/login';
    static RESET_PASSWORD = '/reset-password';
    static PROFILE = '/profile';
    static ACTION = '/action';
    static FARM = '/farm';
    static TYPE = '/type';
    static DASHBOARD = '/dashboard';
    static DEPARTMENT = '/department';
    static ROOM = '/room';
    static ROOM_TYPE = 'room/type';
    static BATCH = '/batch';
    static PLANT = '/batch/plant';
    static STRAIN = 'batch/strain';
    static ACTION_DEFINITION = 'action/definition';
    static SETTINGS = '/settings';
    static ERROR = '/error';
}

export default function Route() {
    return useRoutes([
        {
            path: RoutePath.LOGIN,
            element: <AuthLayout />,
            children: [{ path: RoutePath.ROOT, element: <LoginPage /> }],
        },
        {
            path: RoutePath.RESET_PASSWORD,
            element: <AuthLayout />,
            children: [{ path: RoutePath.ROOT, element: <ResetPasswordPage /> }],
        },
        {
            path: RoutePath.RESET_PASSWORD + '/:id/:token',
            element: <AuthLayout />,
            children: [{ path: RoutePath.ROOT, element: <ResetPasswordPage /> }],
        },
        {
            path: RoutePath.ROOT,
            element: <Navigate to={RoutePath.LOGIN} replace />,
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.PROFILE, element: <ProfilePage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.FARM, element: <FarmPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.SUPPLIER, element: <SupplierPage /> }],
        },
        {
            path: '' + RoutePath.USER,
            element: <BaseLayout />,
            children: [{ path: RoutePath.ROOT, element: <NewUserManagePage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.DASHBOARD, element: <NewDashboardPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.DEPARTMENT, element: <NewDepartmentPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.ROOM, element: <NewRoomPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.ROOM_TYPE, element: <NewRoomTypePage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.BATCH, element: <NewBatchPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.PLANT, element: <NewPlantPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.STRAIN, element: <NewStrainPage /> }],
        },
        {
            path: '' + RoutePath.ACTION,
            element: <BaseLayout />,
            children: [{ path: RoutePath.ROOT, element: <NewActionPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.ACTION_DEFINITION, element: <NewActionDefinitionPage enableCrud={false} /> }],
        },
        {
            path: RoutePath.SETTINGS,
            element: <BaseLayout />,
            children: [{ path: RoutePath.ROOM_TYPE, element: <NewRoomTypePage /> }],
        },
        {
            path: RoutePath.SETTINGS,
            element: <BaseLayout />,
            children: [{ path: RoutePath.ACTION_DEFINITION, element: <NewActionDefinitionPage enableCrud={true} /> }],
        },
        {
            path: RoutePath.SETTINGS,
            element: <BaseLayout />,
            children: [{ path: RoutePath.STRAIN, element: <NewStrainPage /> }],
        },
        {
            path: '',
            element: <BaseLayout />,
            children: [{ path: RoutePath.TYPE + '/*', element: <NewTypePage /> }],
        },
        { path: '*', element: <Navigate to={RoutePath.ERROR} replace /> },
    ]);
}
