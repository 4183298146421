import HomeIcon from '@mui/icons-material/HomeOutlined';
import AccountIcon from '@mui/icons-material/ManageAccountsOutlined';
import UserIcon from '@mui/icons-material/PersonOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import RoomIcon from '@mui/icons-material/RoomServiceOutlined';
import BatchIcon from '@mui/icons-material/BatchPredictionOutlined';
import ActionIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FarmIcon from '@mui/icons-material/NatureOutlined';
import LogoutIcon from '@mui/icons-material/TransitEnterexitOutlined';
import SupplierIcon from '@mui/icons-material/EmojiPeopleOutlined';

export default class MenuModel {
    logout = { name: 'Logout', icon: <LogoutIcon />, path: '/' };
    profile = { name: '', icon: '', path: '/profile' };
    drawer = [
        { name: 'Dashboard', icon: <HomeIcon />, path: '/dashboard' },
        { name: 'Supplier', icon: <SupplierIcon />, path: '/supplier' },
        { name: 'Departments', icon: <ActionIcon />, path: '/department' },
        {
            name: 'Rooms',
            icon: <RoomIcon />,
            path: '#',
            items: [
                { name: 'Manage', path: '/room' },
                { name: 'Types', path: '/room/type' },
            ],
        },
        {
            name: 'Batches',
            icon: <BatchIcon />,
            path: '#',
            items: [
                { name: 'Manage', path: '/batch' },
                // {name: 'Strains', path: '/batch/strain'},
            ],
        },
        {
            name: 'Actions',
            icon: <ActionIcon />,
            path: '#',
            items: [
                { name: 'Manage', path: '/action' },
                { name: 'Definitions', path: '/action/definition' },
            ],
        },
        { name: 'Users', icon: <UserIcon />, path: '/user' },
        {
            name: 'Settings',
            icon: <SettingsIcon />,
            path: '#',
            items: [
                { name: 'Room Types', path: '/settings/room/type' },
                { name: 'Action Definition', path: '/settings/action/definition' },
                { name: 'Strains', path: '/settings/batch/strain' },
            ],
        },
    ];

    appBar = [
        { name: 'Profile', icon: <AccountIcon />, path: '/profile' },
        { name: 'Farm', icon: <FarmIcon />, path: '/farm' },
    ];
}
