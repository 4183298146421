import * as React from 'react';
import { useState } from 'react';
import AppBarComponent from './AppBarComponent';
import { Button, Checkbox, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import AppBarMenuComponent from './AppBarMenuComponent';
import QRCode from 'react-qr-code';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

class FormComponent {
    static textField = (props: {
        state?: any;
        handleOnKeyPress: any;
        handleOnChange: any;
        label: string;
        id: string;
        setValue?: any;
        type?: any;
    }) => {
        const { state, handleOnKeyPress, handleOnChange, label, id, setValue, type } = props;
        const key = id;
        return (
            <TextField
                fullWidth
                label={label}
                id={key}
                //value={setValue || state?.data[key] || state?.selected[key] || ''}
                onKeyPress={handleOnKeyPress}
                onChange={handleOnChange}
                placeholder={label}
                margin="normal"
                type={type || 'text'}
            />
        );
    };

    static selectField = (props: {
        state?: any;
        handleOnChange: any;
        label: string;
        id: string;
        options: any;
        dispatch?: any;
        setValue?: any;
        isBlank?: boolean;
    }) => {
        const { state, handleOnChange, label, id, options, setValue, isBlank } = props;
        const key = id;
        const split = id.split('.');
        let value = setValue || state?.data[key] || state?.selected[key];
        if (split.length >= 2) value = state.data[split[0]] || state.selected[split[0]][split[1]];
        if (value === undefined && state?.data) state.data[id] = options?.length > 0 ? options[0]['id'] : null;

        return (
            <Box mt={2.0}>
                <TextField
                    select
                    fullWidth
                    id={key}
                    label={label}
                    value={isBlank ? 'blank' : value}
                    onChange={handleOnChange}
                    SelectProps={{ native: true }}
                >
                    <option key={'blank'} value={'blank'}></option>
                    {options.map((option: any) => (
                        <option key={option.id} value={option.id}>
                            {' '}
                            {option.name}{' '}
                        </option>
                    ))}
                </TextField>
            </Box>
        );
    };

    static multiSelect = (props: {
        state?: any;
        handleOnChange: any;
        label: string;
        id: string;
        options: any;
        dispatch?: any;
        setValue?: any;
        isBlank?: boolean;
    }) => {
        const { handleOnChange, label, id, options } = props;
        const [selected, setSelected] = React.useState<string[]>([]);

        const handleChange = (event: SelectChangeEvent<typeof selected>) => {
            const {
                target: { value },
            } = event;

            const arr = typeof value === 'string' ? value.split(',') : value;
            setSelected(arr);

            const myArrayFiltered = options.filter((el: any) => {
                return arr.some((f: string) => {
                    return f === el.name;
                });
            });

            handleOnChange(myArrayFiltered);
        };

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (
            <Box mt={2.0}>
                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id={id}
                        multiple
                        value={selected}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {options.map((user: any) => (
                            <MenuItem key={user.id} value={user.name}>
                                <Checkbox checked={selected.indexOf(user.name) > -1} />
                                <ListItemText primary={user.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    static button = (props: { state?: any; handleClick: any; label: string; color?: any }) => {
        const { state, handleClick, label, color } = props;
        const c = color !== undefined ? color : 'inherit';
        return (
            <Button onClick={handleClick} variant="contained" size="large" color={c}>
                {label}
            </Button>
        );
    };

    static appBar = (props: { title: string }) => {
        const { title } = props;
        return (
            <AppBarComponent>
                <Typography variant={'h6'} sx={{ color: 'black' }}>
                    {title}
                </Typography>
                <Box sx={{ position: 'absolute', right: '30px' }}>
                    <AppBarMenuComponent />
                </Box>
            </AppBarComponent>
        );
    };

    static groupCheckBox = (props: { state?: any; handleOnChange: any; label: string; id: string; options: any }) => {
        const { state, handleOnChange, label, id, options } = props;
        const key = id;
        const _getState = () => {
            if (state.data[key] == null || state.data[key].length === 0) return [];
            return Object.keys(state.data).length >= 1 ? state.data[key].split(',') : [];
        };
        let index = 0;
        const checkbox: any = [];
        const checked: any = state.selected[key] !== undefined ? state.selected[key] : _getState();
        if (Object.keys(state.data).length === 0) state.data[key] = checked;

        const _handleChange = (event: any) => {
            const value = event.target.value.toLowerCase();
            const found = checked.indexOf(value.trim().toLowerCase());
            if (found < 0) checked.push(value);
            else {
                const index = checked.indexOf(value, 0);
                if (index > -1) checked.splice(index, 1);
            }
            event.target.value = checked;
            handleOnChange(event);
        };

        return (
            <div>
                <Box mt={1.6} pl={1} mb={1}>
                    <b>{label}</b>
                </Box>
                <Grid container spacing={0}>
                    {options.forEach((option: any) => {
                        const id = option.id.toString().trim().toLowerCase();
                        const isChecked = checked.indexOf(id) >= 0;
                        checkbox.push(
                            <Grid item md={4} xs={4} lg={4} key={option.name}>
                                <Checkbox value={id} id={key} onChange={_handleChange} checked={isChecked} />
                                <small>{option.name}</small>
                            </Grid>,
                        );
                        index += 1;
                    })}
                    {checkbox}
                </Grid>
            </div>
        );
    };

    static textArea = (props: { state?: any; handleOnChange: any; label: string; id: string; setValue?: any }) => {
        const { state, handleOnChange, label, id, setValue } = props;
        const value = setValue || state?.data[id] || state?.selected[id];
        return (
            <Box mb={1.7} mt={1.7}>
                <TextField
                    id={id}
                    label={label}
                    fullWidth
                    multiline
                    rows={2}
                    onChange={handleOnChange}
                    defaultValue={value}
                    variant="outlined"
                />
            </Box>
        );
    };

    static qrCode = (props: { data: any }) => {
        if (props.data == null) return <div />;
        return (
            <Box sx={{ float: 'right' }}>
                <QRCode value={props.data} size={120} level={'Q'} />
            </Box>
        );
    };

    static counterButtons = (props: {
        state?: any;
        handleOnKeyPress: any;
        handleOnChange: any;
        label: string;
        id: string;
        setValue?: any;
    }) => {
        const { state, handleOnKeyPress, handleOnChange, label, id, setValue } = props;
        const defaultValue = state?.data ? state?.data[id] || state?.selected[id] : setValue || 0;
        const [counter, setCounter] = useState(defaultValue !== undefined ? defaultValue : 0);

        const trigger = (value: any) => {
            const events = { target: { value: 0, id: id } };
            events.target.value = value;
            handleOnChange(events);
        };
        const handleIncrement = () => {
            const local = counter + 1;
            setCounter(local);
            trigger(local);
        };
        const handleDecrement = () => {
            const local = counter >= 1 ? counter - 1 : 0;
            setCounter(local);
            trigger(local);
        };
        return (
            <Box mt={1}>
                <Grid container spacing={0}>
                    <Grid item xs={2}>
                        <Button onClick={handleDecrement} variant={'outlined'} fullWidth sx={{ height: '55px' }}>
                            -
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            placeholder={label}
                            fullWidth
                            key={id}
                            id={id}
                            label={label}
                            value={counter || state?.selected[id] || 0}
                            onBlur={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={handleIncrement} variant={'outlined'} fullWidth sx={{ height: '55px' }}>
                            +
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    };
}

export default FormComponent;
