import {StorageService} from "app/services/storage/StorageService"
import {useEffect, useState} from "react";
import {RoutePath} from "app/routes/Route";
import {Navigate} from "react-router-dom";

const AuthGuard = (props: any) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [content, setContent] = useState(<div/>);

    useEffect(() => {
        StorageService.read().then((result) => {
            if (result !== null) {
                setLoggedIn(true);
            } else {
                setContent(<Navigate to={RoutePath.LOGIN}/>);
            }
        });
    }, []);
    return !loggedIn ? content : props.children;
}

export default AuthGuard
