import Config from 'app/configs/Config';


export class StorageService {
    static key = Config.AUTH_STORAGE_NAME;

    static write = (result: any) => {
        localStorage.setItem(StorageService.key, JSON.stringify(result));
    };

    static delete = () => {
        localStorage.removeItem(StorageService.key);
    };

    static read = async (): Promise<any> => {
        const stored = localStorage.getItem(StorageService.key) || '';
        if (stored.trim().length <= 0) return null;
        return JSON.parse(stored);
    };
}
