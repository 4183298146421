export enum ActionDefinitionEnum {
    CUSTOM = 'CUSTOM',
    HARVEST = 'HARVEST',
    SPRAY = 'SPRAY',
    ASSOCIATED_ROOM = 'ASSOCIATED_ROOM',
    CREATE_ORIGIN = 'CREATE_ORIGIN',
    REMOVE_PLANTS_TO_BATCHES = 'REMOVE_PLANTS_TO_BATCHES',
    ADD_PLANTS_TO_BATCHES = 'ADD_PLANTS_TO_BATCHES',
    IMPORT_PLANTS = 'IMPORT_PLANTS'
}
