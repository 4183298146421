import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Notification from '@mui/icons-material/NotificationsNoneRounded';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import MenuModel from 'app/models/MenuModel';
import { makeStyles } from '@mui/styles';
import RightSidePaneComponent from './RightSidePaneComponent';
import { useState } from 'react';

const Styles: any = makeStyles((theme) => ({
    menu: {
        '& span, & svg': {
            fontSize: '0.90em',
        },
    },
}));

const AppBarMenuComponent = () => {
    const styles = Styles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const menuModel = new MenuModel();
    const open = Boolean(anchorEl);
    const [state, setState] = useState({ isPaneOpen: false, isPaneOpenLeft: false });

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const buildItems = () => {
        const items: JSX.Element[] = [];
        menuModel.appBar.forEach((item) => {
            items.push(
                <ListItem button onClick={() => navigate(item.path)} key={item.path}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} className={styles.menu} />
                </ListItem>,
            );
        });
        return items;
    };

    const handleOnOpen = () => {
        setState({ isPaneOpenLeft: false, isPaneOpen: !state.isPaneOpen });
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Menu">
                    <IconButton size="small" sx={{ ml: 2 }} onClick={handleOnOpen}>
                        <Notification sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Tooltip>
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                    <Avatar sx={{ width: 32, height: 32 }} />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: '250px',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {buildItems()}
                <Divider />
                <ListItem button onClick={() => navigate(menuModel.logout.path)}>
                    <ListItemIcon>{menuModel.logout.icon}</ListItemIcon>
                    <ListItemText primary={menuModel.logout.name} className={styles.menu} />
                </ListItem>
            </Menu>
            <RightSidePaneComponent paneState={state} setPaneState={setState} />
        </React.Fragment>
    );
};

export default AppBarMenuComponent;
