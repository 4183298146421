import {makeStyles, styled} from "@mui/styles";

export const MakeStyles: any = makeStyles({
    card: {
        maxWidth: 240,
        height: 230,
        padding: 10,
        marginBottom: 1,
        cursor: 'pointer'
    },
    alignLeftPad: {
        textAlign: 'left',
        paddingLeft: 10
    },
    bgTransparent: {
        backgroundColor: 'transparent'
    },
    spaceRight: {
        marginRight: 5
    }
});

export const StyledRoomItem = styled("div")(({}) => ({
    marginBottom: 15,
    textAlign: 'left',
    backgroundColor: 'transparent',
}));

