import Config from 'app/configs/Config';
import UserModel from 'app/models/UserModel';
import {ApiMethod, ConnectionService} from 'app/services/api/ApiService';

import {Store} from "app/stores/Store";

export class ResetPasswordService {
    userModel = new UserModel(Store);

    requestResetPassword = async (args: { email: string }): Promise<boolean> => {
        const data: any = {
            email: args.email
        };
        return await ConnectionService.connect({
            url: Config.PATH_RESET_PASSWORD,
            method: ApiMethod.POST,
            data: data
        })
            .then((result) => {
                return true;
            })
            .catch(function (error: any) {
                return false;
            });
    };

    resetPassword = async (args: { password: string }): Promise<boolean> => {
        const data: any = {
            password: args.password
        };
        return await ConnectionService.connect({
            url: Config.PATH_RESET_PASSWORD,
            method: ApiMethod.POST,
            data: data
        })
            .then((result) => {
                return true;
            })
            .catch(function (error: any) {
                return false;
            });
    };

}
