import {ApiService} from "app/services/api/ApiService";
import {Args} from "app/models/Model";
import {Action} from "./State";

export class Request {
    method: any;
    private pattern = 'any:id:skip:limit';

    request = async (args: Args) => {
        const url = this.toUrl(args);
        const _getData = () => {
            const data = args.state !== undefined && args.state.data !== undefined ? args.state.data : null;
            return args.state !== undefined && Object.keys(args.state.selected).length >= 1 ? {...args.state.selected, ...args.state.data} : data;
        }
        return await ApiService.request({method: this.method, url: url, data: _getData()}).then((payload) => {
            this.setPayload(payload, args);
            Promise.resolve(payload);
        });
    }

    toUrl = (args: Args) => {
        const params = args.setter.params;
        const config = args.config;
        const paths: any[] = [];
        if (params === undefined) return config.path;

        this.pattern.split(":").map((name: any) => {
            if (params[name] !== undefined && params[name].toString().length >= 1) {
                paths.push(params[name].toString())
            }
        });

        const query = paths.length >= 1 ? paths.join("/") : '';
        return config.path + "/" + query;
    }

    setPayload = async (payload: any, args: Args) => {
        const action = ({type: args.action, payload: payload});
        const state = args.store.dispatch(action);

        this.ifNeedDispatch(state, args);
        this.ifNeedOnSet(state, args);
    }

    ifNeedDispatch = (state: any, args: Args) => {
        if (args.setter.dispatch !== undefined) args.setter.dispatch({type: Action.setList, payload: state.payload.data});
    }

    ifNeedOnSet = (state: any, args: Args) => {
        const mutation = this.getMutation(state, args);
        if (args.setter.onSet) {
            args.setter.set(mutation || state.payload.data);
            args.setter.onSet(state.payload.data);
        } else args.setter.set(mutation || state.payload.data);
    }

    getMutation = (state: any, args: Args) => {
        if (args.id !== undefined && args.id.length >= 1) {
            let index = 0;
            const list = args.state.list;
            if (Object.keys(list).length <= 0) return null;
            if (!(list instanceof Array)) return null;

            list.forEach((result: any) => {
                const id = result._id || result.id;
                const found = id == args.id;
                if (found) {
                    list[index] = {...result, ...args.state.data};
                    if (this.method.toLowerCase() == 'delete') list.splice(index, 1);
                    return list;
                }
                ++index
            });
            return list;
        }
        if (this.method.toLowerCase() == 'post') {
            args.state.list.unshift(state.payload.data);
            return args.state.list;
        }
        return null;
    }

    perform = async (args: Args) => {
        return new Promise(async () => {
            return this.request(args);
        })
    }

}
