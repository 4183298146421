import {Box, Button, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useReducer, useState} from "react";
import WrapperComponent from "./components/WrapperComponent";
import DialogFormComponent from "./components/DialogFormComponent";
import Model from "app/models/Model";
import {Action, Dispatch, Reducer} from "app/models/Model/State";
import SimpleTableComponent, {TableAlignmentTypes, TableComponentTypes} from "app/pages/components/SimpleTableComponent";
import SimpleTableBoxComponent from "app/pages/components/SimpleTableBoxComponent";
import UserModel from "app/models/UserModel";
import FormComponent from "./components/FormComponent";
import RoleModel from "app/models/RoleModel";
import PageModel from "app/models/PageModel";
import Format from "app/utils/Format";
import NotificationComponent from "./components/NotificationComponent";
import FormatTime from "app/utils/FormatTime";

const UserPage = () => {
    const model = Model.prepare({User: new UserModel(), Page: new PageModel()});
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [pageInfo, setPageInfo] = useState({title: ''});
    const [userList, setUserList] = useState([]);
    const [pageList, setPageList] = useState([]);
    const [userData, setUserData] = useState({farm: '', role: ''});
    const [eaDialog, setEADialog] = useState(false);
    const [notification, setNotification] = useState({});
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const tableComponentTypes = TableComponentTypes;
    const tableAlignmentTypes = TableAlignmentTypes;

    useEffect(() => {
        model.User.fetch("Current").with({set: setUserData, dispatch: dispatch});
        model.User.fetch("AllUsers").with({set: setUserList, dispatch: dispatch});
        model.Page.fetch("AllPages").with({set: setPageList});
    }, [])

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setNotification(payload);
            setEADialog(false);
        };
        dispatch({type: Action.setIsWorking, payload: true});
        state.data.farm = userData.farm;

        if (state.pageInfo.type == 'add') model.User.add(state).with({set: setUserList, onSet: _onSet});
        else model.User.update(state).with({set: setUserList, onSet: _onSet});
    };

    const handleOnKeyPress = (event: any) => {
        const {key} = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    }

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({type: Action.setData, payload: payload});
    }

    const handleOnEditClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        dispatch({type: Action.setPageInfo, payload: {title: 'Edit ' + data.name, type: 'edit'}});
        setEADialog(true);
    }

    const handleOnDeleteClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        dispatch({type: Action.setPageInfo, payload: {title: 'Delete ' + data.name, type: 'delete'}});
        setDeleteDialog(true);
    }

    const handleOnDeleteConfirmed = (event: any) => {
        const _onSet = (payload: any) => setDeleteDialog(false);
        model.User.delete(state).with({set: setUserList, onSet: _onSet});
    }

    const handleOnAddClick = (event: any) => {
        dispatch({type: Action.setSelected, payload: {role: RoleModel.getRole()[0].id}});
        dispatch({type: Action.setPageInfo, payload: {title: 'Add User', type: 'add'}});
        setEADialog(true);
    }

    const handleOnClickStatus = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        setPageInfo({title: 'Status ' + data.name});
        setStatusDialog(true);
    }

    const handleOnStatusConfirmed = (event: any) => {
        const _onSet = (payload: any) => setStatusDialog(false);
        const data = state?.selected;
        data.status = data.status == 'active' ? 'inActive' : 'active';
        dispatch({type: Action.setSingleUpdate, payload: data});
        model.User.update(state).with({set: setUserList, onSet: _onSet});
    }

    const buildEditDialogContent = () => {
        return <Box mb={2}>
            <FormComponent.textField state={state} label={'Name'} id={"name"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.textField state={state} label={'Email'} id={"email"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.textField state={state} label={'Phone'} id={"phone"} handleOnChange={handleOnChange} handleOnKeyPress={handleOnKeyPress}/>
            <FormComponent.selectField state={state} label={'Role'} id={"role"} handleOnChange={handleOnChange} options={RoleModel.getRole()}/>
            <FormComponent.groupCheckBox state={state} label={'Allowed Pages'} id={"allowedPages"} handleOnChange={handleOnChange}
                                         options={Format.toProperCheckbox({list: pageList})}/>
        </Box>;
    }

    const buildEditDialogActions = () => {
        return <React.Fragment><Button onClick={handleOnSubmit} variant="contained" size="large" color="primary">Save</Button></React.Fragment>;
    }

    const buildDeleteDialogContent = () => {
        return <React.Fragment><Typography gutterBottom>Are you sure you want to delete this {state.selected['name']}?</Typography></React.Fragment>;
    }

    const buildDeleteDialogActions = () => {
        return <React.Fragment>
            <Button onClick={() => setDeleteDialog(false)} variant="contained" size="large" color="inherit">Cancel</Button>
            <FormComponent.button label={'Delete'} handleClick={handleOnDeleteConfirmed}/>
        </React.Fragment>;
    }

    const buildStatusIcon = (data: any) => data.status === 'active' ? 'checked' : 'close';

    const buildActions = () => {
        if (userData === null) return [];
        if (RoleModel.isSuperAdmin(userData['role'])) {
            return [{icon: 'edit', event: handleOnEditClick}, {icon: 'delete', event: handleOnDeleteClick}]
        }
        return [];
    }

    const buildStatusDialogContent = () => {
        return <React.Fragment><Typography gutterBottom>Are you sure you want to enable/disable this {state.selected['name']}?</Typography></React.Fragment>;
    }

    const buildStatusDialogActions = () => {
        return <React.Fragment>
            <FormComponent.button label={'Cancel'} handleClick={() => setStatusDialog(false)}/>
            <FormComponent.button label={'Confirm'} handleClick={handleOnStatusConfirmed} color={'info'}/>
        </React.Fragment>;
    }

    const buildStatus = () => {
        const event = RoleModel.isSuperAdmin(userData['role']) ? handleOnClickStatus : null;
        return {display: 'Status', type: tableComponentTypes.BUTTONS, components: [{icon: buildStatusIcon, event: event}]};
    }

    return (
        <React.Fragment>
            <DialogFormComponent params={{dispatch: dispatch, content: buildEditDialogContent(), actions: buildEditDialogActions(), title: state.pageInfo.title}}
                                 dialog={{dialog: eaDialog, setDialog: setEADialog}}/>
            <DialogFormComponent params={{dispatch: dispatch, content: buildDeleteDialogContent(), actions: buildDeleteDialogActions(), title: state.pageInfo.title}}
                                 dialog={{dialog: deleteDialog, setDialog: setDeleteDialog}}/>
            <DialogFormComponent params={{content: buildStatusDialogContent(), actions: buildStatusDialogActions(), title: pageInfo.title}}
                                 dialog={{dialog: statusDialog, setDialog: setStatusDialog}}/>
            <WrapperComponent>
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', height: '612px'}} className={'manage'}>
                    <SimpleTableBoxComponent title={'Manage User'} handleOnAddClick={handleOnAddClick} show={RoleModel.isSuperAdmin(userData['role'])}/>
                    <SimpleTableComponent params={{
                        headers: [
                            {display: 'User Name', type: tableComponentTypes.PLAIN, field: "name", withEdit: RoleModel.isSuperAdmin(userData['role']) ? handleOnEditClick : null},
                            {display: 'Email', type: tableComponentTypes.PLAIN, field: "email"},
                            {display: 'Phone', type: tableComponentTypes.PLAIN, field: "phone"},
                            {display: 'Role', type: tableComponentTypes.PLAIN, field: "role", populate: {list: RoleModel.getRole(), name: 'name'}},
                            buildStatus(),
                            {display: 'Created', type: tableComponentTypes.PLAIN, field: "dateCreated", format: FormatTime.toDateTime},
                            {
                                display: 'Option', type: tableComponentTypes.BUTTONS, align: tableAlignmentTypes.LEFT, components: buildActions()
                            }],
                        data: userList?.filter((f: any) => f?._id !== (userData as any)?._id),
                    }}/>
                </Box>
            </WrapperComponent>
            <NotificationComponent setNotification={setNotification} notification={notification}/>
        </React.Fragment>
    )
}

export default UserPage;
