import React, { useEffect, useReducer, useState } from 'react';
import { Box, Button, CssBaseline, IconButton, TextField, Typography } from '@mui/material';
import { Action, Dispatch, Reducer } from 'app/models/Model/State';
import DialogFormComponent from './DialogFormComponent';
import FormComponent from './FormComponent';
import Format from 'app/utils/Format';
import { ActionDefinitionEnum } from '../../enums/ActionDefinition';
import Model from '../../models/Model';
import DepartmentModel from '../../models/DepartmentModel';
import RoomModel from '../../models/RoomModel';
import BatchModel from '../../models/BatchModel';
import StrainModel from '../../models/StrainModel';
import PlantModel from '../../models/PlantModel';
import SupplierModel from '../../models/SupplierModel';
import StatusModel from '../../models/StatusModel';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import _ from 'lodash';
import SprayTypeModel from '../../models/SprayTypeModel';
import { socket } from '../../services/socket/SocketIO';

const BuildActionDefinitionTypeDialog = (props: any) => {
    const [weightValue, setWeightValue] = useState('0.00');
    const [weightsList, setWeightsList] = useState([]);
    const [printersList, setPrintersList] = useState([]);
    const [printer, setPrinter] = useState('');
    const [source, setSource] = useState({});

    const model = Model.prepare({
        SupplierModel: new SupplierModel(),
        SprayTypeModel: new SprayTypeModel(),
        Department: new DepartmentModel(),
        Room: new RoomModel(),
        Batch: new BatchModel(),
        Strain: new StrainModel(),
        Plant: new PlantModel(),
    });
    const { type, title } = props.params;
    const [addSupplierDialog, setAddSupplierDialog] = useState(false);
    const [addOriginDialog, setAddOriginDialog] = useState(false);
    const [addPlantDialog, setAddPlantDialog] = useState(false);
    const [addSprayTypeDialog, setAddSprayTypeDialog] = useState(false);

    const [departmentList, setDepartmentList] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [strainList, setStrainList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [sprayTypeList, setSprayTypeList] = useState([]);
    const [plantList, setPlantList] = useState([]);
    const [getPayload, setPayload] = useState({});
    const [stateSupplier, dispatchSupplier] = useReducer(Reducer, Dispatch);
    const [stateSprayType, dispatchSprayType] = useReducer(Reducer, Dispatch);
    const [stateOrigin, dispatchOrigin] = useReducer(Reducer, Dispatch);
    const [statePlant, dispatchPlant] = useReducer(Reducer, Dispatch);

    const { dialog, setDialog } = props.dialog;
    const { handleOnSubmit } = props;
    const { handleOnCancel } = props;
    let payload: any = {};

    useEffect(() => {
        model.SupplierModel.fetch('AllSuppliers').with({ set: setSupplierList, dispatch: dispatchSupplier });
        model.SprayTypeModel.fetch('AllSprayType').with({ set: setSprayTypeList, dispatch: dispatchSprayType });
        model.Department.fetch('AllDepartments').with({ set: setDepartmentList });
        model.Room.fetch('AllRooms').with({ set: setRoomList, params: { skip: 0, limit: 15 } });
        model.Strain.fetch('AllStrains').with({ set: setStrainList, dispatch: dispatchOrigin });
        model.Plant.fetch('AllPlants').with({
            set: setPlantList,
            dispatch: dispatchPlant,
            params: { skip: 0, limit: 15 },
        });
    }, []);

    useEffect(() => {
        return () => {
            //componentwillunmount
            socket.removeAllListeners('weight-value');
        };
    }, []);

    useEffect(() => {
        if (type === ActionDefinitionEnum.HARVEST) {
            socket.emit('get-weights', (info: any) => {
                const arr: any = [];
                for (const key in info) {
                    arr.push(info[key]);
                }

                setWeightsList(arr);
            });

            socket.emit('get-printers', (info: any) => {
                const arr: any = [];
                for (const key in info) {
                    arr.push(info[key]);
                }

                setPrintersList(arr);
            });
        } else {
            socket.removeAllListeners('weight-value');
        }
    }, [type]);

    const handleOnChange = (event: any) => {
        if (event.target.id === 'room') {
            model.Batch.fetch('ByRoom').with({
                set: setBatchList,
                params: { id: event.target.value, skip: 0, limit: 15 },
            });
        }
        payload = { ...getPayload };
        switch (type) {
            case ActionDefinitionEnum.CUSTOM:
                payload['customProp'] = {
                    ...payload['customProp'],
                    [event.target.id]: event.target.value,
                };
                break;
            case ActionDefinitionEnum.HARVEST:
                payload['harvestProp'] = {
                    ...payload['harvestProp'],
                    [event.target.id]: event.target.value,
                };
                break;
            case ActionDefinitionEnum.ASSOCIATED_ROOM:
                payload['associatedRoomProp'] = {
                    ...payload['associatedRoomProp'],
                    [event.target.id]: event.target.value,
                };
                break;
            case ActionDefinitionEnum.CREATE_ORIGIN:
                payload['createOriginProp'] = {
                    ...payload['createOriginProp'],
                    [event.target.id]: event.target.value,
                };
                break;
            case ActionDefinitionEnum.SPRAY:
                payload['sprayProp'] = {
                    ...payload['sprayProp'],
                    [event.target.id]: event.target.value,
                };
                break;
            case ActionDefinitionEnum.REMOVE_PLANTS_TO_BATCHES:
                payload['removePlantsBatchesProp'] = {
                    ...payload['removePlantsBatchesProp'],
                    [event.target.id]: event.target.value,
                };
                break;
            case ActionDefinitionEnum.ADD_PLANTS_TO_BATCHES:
                payload['addPlantsBatchesProp'] = {
                    ...payload['addPlantsBatchesProp'],
                    [event.target.id]: event.target.value,
                };
                if (event.target.id === 'supplier' || event.target.id === 'department') {
                    payload['addPlantsBatchesProp'] = {
                        ...payload['addPlantsBatchesProp'],
                        ['source.ref']: event.target.value,
                    };
                }
                break;
            case ActionDefinitionEnum.IMPORT_PLANTS:
                payload['importPlants'] = {
                    ...payload['importPlants'],
                    [event.target.id]: event.target.value,
                };
                break;
        }
        setPayload(payload);
    };

    const handleSubmit = () => {
        handleOnSubmit(getPayload);
    };

    const handleOnKeyPress = (event: any) => {
        return;
    };

    // Supplier Dialog
    const handleOnKeyPressSupplier = (event: any) => {
        const { key } = event;
        if (key === 'Enter') stateSupplier.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChangeSupplier = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatchSupplier({ type: Action.setData, payload: payload });
    };

    const buildAddSupplierDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={stateSupplier}
                    label={'Name'}
                    id={'name'}
                    handleOnChange={handleOnChangeSupplier}
                    handleOnKeyPress={handleOnKeyPressSupplier}
                />
                <TextField
                    fullWidth
                    label="Phone"
                    id="phone"
                    value={stateSupplier.data['phone'] || stateSupplier.selected['phone']}
                    onChange={handleOnChangeSupplier}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Email"
                    id="email"
                    value={stateSupplier.data['email'] || stateSupplier.selected['email']}
                    onChange={handleOnChangeSupplier}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="State"
                    id="state"
                    value={stateSupplier.data['state'] || stateSupplier.selected['state']}
                    onChange={handleOnChangeSupplier}
                    margin="normal"
                />
                <FormComponent.selectField
                    state={stateSupplier}
                    label={'Status'}
                    id={'status'}
                    handleOnChange={handleOnChangeSupplier}
                    options={StatusModel.getActive()}
                />
            </Box>
        );
    };

    const buildAddSupplierDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Save'} handleClick={handleOnSubmitSupplier} color={'info'} />
                <FormComponent.button label={'Cancel'} handleClick={handleOnCancelSupplier} />
            </React.Fragment>
        );
    };

    const handleOnSubmitSupplier = (event: any) => {
        const _onSet = (payload: any) => {
            setAddSupplierDialog(false);
        };
        dispatchSupplier({ type: Action.setIsWorking, payload: true });
        model.SupplierModel.add(stateSupplier).with({ set: setSupplierList, onSet: _onSet });
    };

    const handleOnCancelSupplier = (event: any) => {
        dispatchSupplier({ type: Action.setReset, payload: null });
        setAddSupplierDialog(false);
    };

    const handleOnAddSupplierClick = (event: any) => {
        dispatchSupplier({ type: Action.setReset, payload: null });
        dispatchSupplier({ type: Action.setSelected, payload: {} });
        setAddSupplierDialog(true);
    };

    // Origin Dialog
    const handleOnKeyPressOrigin = (event: any) => {
        const { key } = event;
        if (key === 'Enter') stateOrigin.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChangeOrigin = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatchOrigin({ type: Action.setData, payload: payload });
    };

    const buildAddOriginDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={stateOrigin}
                    label={'Name'}
                    id={'name'}
                    handleOnChange={handleOnChangeOrigin}
                    handleOnKeyPress={handleOnKeyPressOrigin}
                />
                <FormComponent.selectField
                    state={stateOrigin}
                    label={'Status'}
                    id={'status'}
                    handleOnChange={handleOnChangeOrigin}
                    options={StatusModel.getActive()}
                />
            </Box>
        );
    };

    const buildAddOriginDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Save'} handleClick={handleOnSubmitOrigin} color={'info'} />
                <FormComponent.button label={'Cancel'} handleClick={handleOnCancelOrigin} />
            </React.Fragment>
        );
    };

    const handleOnSubmitOrigin = (event: any) => {
        const _onSet = (payload: any) => {
            setAddOriginDialog(false);
        };
        dispatchOrigin({ type: Action.setIsWorking, payload: true });
        model.Strain.add(stateOrigin).with({ set: setStrainList, onSet: _onSet });
    };

    const handleOnCancelOrigin = (event: any) => {
        dispatchOrigin({ type: Action.setReset, payload: null });
        setAddOriginDialog(false);
    };

    const handleOnAddOriginClick = (event: any) => {
        dispatchOrigin({ type: Action.setReset, payload: null });
        dispatchOrigin({ type: Action.setSelected, payload: {} });
        setAddOriginDialog(true);
    };

    // Plant Dialog
    const handleOnKeyPressPlant = (event: any) => {
        const { key } = event;
        if (key === 'Enter') statePlant.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChangePlant = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatchPlant({ type: Action.setData, payload: payload });
    };

    const buildAddPlantDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={statePlant}
                    label={'Name'}
                    id={'name'}
                    handleOnChange={handleOnChangePlant}
                    handleOnKeyPress={handleOnKeyPressPlant}
                />
                <FormComponent.textField
                    state={statePlant}
                    label={'Code'}
                    id={'code'}
                    handleOnChange={handleOnChangePlant}
                    handleOnKeyPress={handleOnKeyPressPlant}
                />
            </Box>
        );
    };

    const buildAddPlantDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Save'} handleClick={handleOnSubmitPlant} color={'info'} />
                <FormComponent.button label={'Cancel'} handleClick={handleOnCancelPlant} />
            </React.Fragment>
        );
    };

    const handleOnSubmitPlant = (event: any) => {
        const _onSet = (payload: any) => {
            setAddPlantDialog(false);
        };
        dispatchPlant({ type: Action.setIsWorking, payload: true });
        model.Plant.add(statePlant).with({ set: setPlantList, onSet: _onSet });
    };

    const handleOnCancelPlant = (event: any) => {
        dispatchPlant({ type: Action.setReset, payload: null });
        setAddPlantDialog(false);
    };

    const handleOnAddPlantClick = (event: any) => {
        dispatchPlant({ type: Action.setSelected, payload: {} });
        setAddPlantDialog(true);
    };

    // Spray Type Dialog
    const handleOnKeyPressSprayType = (event: any) => {
        const { key } = event;
        if (key === 'Enter') stateSprayType.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChangeSprayType = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatchSprayType({ type: Action.setData, payload: payload });
    };

    const buildAddSprayTypeDialogContent = () => {
        return (
            <Box mb={2}>
                <FormComponent.textField
                    state={stateSprayType}
                    label={'Name'}
                    id={'name'}
                    handleOnChange={handleOnChangeSprayType}
                    handleOnKeyPress={handleOnKeyPressSprayType}
                />
                <FormComponent.textField
                    state={stateSprayType}
                    label={'Manufacture'}
                    id={'manufacture'}
                    handleOnChange={handleOnChangeSprayType}
                    handleOnKeyPress={handleOnKeyPressSprayType}
                />
                <FormComponent.textField
                    state={stateSprayType}
                    label={'Note'}
                    id={'note'}
                    handleOnChange={handleOnChangeSprayType}
                    handleOnKeyPress={handleOnKeyPressSprayType}
                />
            </Box>
        );
    };

    const buildAddSprayTypeDialogActions = () => {
        return (
            <React.Fragment>
                <FormComponent.button label={'Save'} handleClick={handleOnSubmitSprayType} color={'info'} />
                <FormComponent.button label={'Cancel'} handleClick={handleOnCancelSprayType} />
            </React.Fragment>
        );
    };

    const handleOnSubmitSprayType = (event: any) => {
        const _onSet = (payload: any) => {
            setAddSprayTypeDialog(false);
        };
        dispatchSprayType({ type: Action.setIsWorking, payload: true });
        model.SprayTypeModel.add(stateSprayType).with({ set: setSprayTypeList, onSet: _onSet });
    };

    const handleOnCancelSprayType = (event: any) => {
        dispatchSprayType({ type: Action.setReset, payload: null });
        setAddSprayTypeDialog(false);
    };

    const handleOnAddSprayTypeClick = (event: any) => {
        dispatchSprayType({ type: Action.setReset, payload: null });
        dispatchSprayType({ type: Action.setSelected, payload: {} });
        setAddSprayTypeDialog(true);
    };

    const handleOnChangeWeight = (event: any) => {
        setWeightValue('0.00');
        if (event.target.value === 'blank') {
            socket.removeAllListeners('weight-value');
        } else {
            socket.on('weight-value', (v: any) => {
                //console.log(v);
                if (v.id === event.target.value) {
                    setWeightValue(v.value);
                }
            });
        }
    };

    const handleOnPrint = () => {
        socket.emit('print', { _id: printer, content: 'weightValue: ' + weightValue });
    };

    const handleOnChangePrinter = (event: any) => {
        setPrinter(event.target.value);
    };

    const handleOnChangeSource = (event: any) => {
        setSource(event.target.value);
        payload['addPlantsBatchesProp'] = {
            ...payload['addPlantsBatchesProp'],
            ['source.refPath']: event.target.value,
        };
        setPayload(payload);
    };

    const buildEditDialogContent = () => {
        if (type) {
            switch (type) {
                case ActionDefinitionEnum.CUSTOM:
                    return (
                        <Box mb={2}>
                            <FormComponent.textField
                                label={'Custom Name'}
                                id={'name'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />
                        </Box>
                    );
                case ActionDefinitionEnum.HARVEST:
                    return (
                        <Box mb={2}>
                            <FormComponent.selectField
                                label={'Select Room'}
                                id={'room'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: roomList,
                                    name: 'name',
                                })}
                            />
                            {batchList?.length > 0 ? (
                                <FormComponent.selectField
                                    label={'Select Batch'}
                                    id={'batch'}
                                    handleOnChange={handleOnChange}
                                    options={Format.toSelectOptions({
                                        list: batchList,
                                        name: 'name',
                                    })}
                                />
                            ) : (
                                <></>
                            )}
                            <FormComponent.counterButtons
                                label={'Availability'}
                                id={'availability'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />

                            <FormComponent.textArea label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                            <FormComponent.textField
                                label={'Number of Boxes'}
                                id={'noBox'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />

                            <FormComponent.selectField
                                label={'Select Weight'}
                                id={'_id'}
                                handleOnChange={handleOnChangeWeight}
                                options={Format.toSelectOptions({
                                    list: weightsList,
                                    name: 'name',
                                })}
                            />

                            <Box
                                sx={{
                                    height: 100,
                                    border: '1px solid',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h2">
                                    {weightValue} <small>Kg</small>
                                </Typography>
                            </Box>

                            <FormComponent.selectField
                                label={'Select Printer'}
                                id={'printer'}
                                handleOnChange={handleOnChangePrinter}
                                options={Format.toSelectOptions({
                                    list: printersList,
                                    name: 'name',
                                })}
                            />

                            <Button onClick={handleOnPrint} variant="contained" fullWidth sx={{ height: '55px' }}>
                                Print QR Code
                            </Button>
                        </Box>
                    );
                case ActionDefinitionEnum.SPRAY:
                    return (
                        <Box mb={2}>
                            <DialogFormComponent
                                params={{
                                    content: buildAddSprayTypeDialogContent(),
                                    actions: buildAddSprayTypeDialogActions(),
                                    title: 'Add Plant',
                                }}
                                dialog={{ dialog: addSprayTypeDialog, setDialog: setAddSprayTypeDialog }}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Box sx={{ width: '90%' }}>
                                    <FormComponent.selectField
                                        label={'Select Spray Type'}
                                        id={'sprayType'}
                                        handleOnChange={handleOnChange}
                                        options={Format.toSelectOptions({
                                            list: sprayTypeList,
                                            name: 'name',
                                        })}
                                    />
                                </Box>
                                <IconButton aria-label="add" sx={{ width: '10%' }}>
                                    <AddCircleIcon style={{ fontSize: 40 }} onClick={handleOnAddSprayTypeClick} />
                                </IconButton>
                            </Box>

                            <FormComponent.selectField
                                label={'Select Department'}
                                id={'department'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: departmentList,
                                    name: 'name',
                                })}
                            />

                            <FormComponent.selectField
                                label={'Select Room'}
                                id={'room'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: roomList,
                                    name: 'name',
                                })}
                            />

                            {batchList?.length > 0 ? (
                                <FormComponent.selectField
                                    label={'Select Batch'}
                                    id={'batch'}
                                    handleOnChange={handleOnChange}
                                    options={Format.toSelectOptions({
                                        list: batchList,
                                        name: 'name',
                                    })}
                                />
                            ) : (
                                <></>
                            )}

                            <FormComponent.textArea label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                        </Box>
                    );
                case ActionDefinitionEnum.ASSOCIATED_ROOM:
                    return (
                        <Box mb={2}>
                            <FormComponent.selectField
                                label={'Select Origin'}
                                id={'strain'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: strainList,
                                    name: 'name',
                                })}
                            />
                            <FormComponent.selectField
                                label={'Select Department'}
                                id={'department'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: departmentList,
                                    name: 'name',
                                })}
                            />
                            <FormComponent.counterButtons
                                label={'Availability'}
                                id={'availability'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />

                            <FormComponent.selectField
                                label={'Select Room'}
                                id={'room'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: roomList,
                                    name: 'name',
                                })}
                            />

                            {batchList?.length > 0 ? (
                                <FormComponent.selectField
                                    label={'Select Batch'}
                                    id={'batch'}
                                    handleOnChange={handleOnChange}
                                    options={Format.toSelectOptions({
                                        list: batchList,
                                        name: 'name',
                                    })}
                                />
                            ) : (
                                <></>
                            )}

                            <FormComponent.textArea label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                        </Box>
                    );
                case ActionDefinitionEnum.CREATE_ORIGIN:
                    return (
                        <Box mb={2}>
                            <FormComponent.textField
                                label={'Name'}
                                id={'name'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />

                            <FormComponent.textField
                                label={'Source'}
                                id={'source'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />

                            <FormComponent.selectField
                                label={'Select Type'}
                                id={'type'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: [
                                        { id: 'Plant', name: 'Plant' },
                                        { id: 'Seed', name: 'Seed' },
                                    ],
                                    name: 'name',
                                })}
                            />

                            <FormComponent.textArea label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                        </Box>
                    );
                case ActionDefinitionEnum.REMOVE_PLANTS_TO_BATCHES:
                    return (
                        <Box mb={2}>
                            <FormComponent.counterButtons
                                label={'How Many Plants'}
                                id={'availability'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />

                            <TextField
                                fullWidth
                                label={'Reason'}
                                id={'reason'}
                                onKeyPress={handleOnKeyPress}
                                onChange={handleOnChange}
                                placeholder={'Reason'}
                                margin="normal"
                                type={type || 'text'}
                            />

                            <FormComponent.textArea label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                        </Box>
                    );
                case ActionDefinitionEnum.ADD_PLANTS_TO_BATCHES:
                    return (
                        <Box mb={2}>
                            <FormComponent.selectField
                                label={'Select Source'}
                                id={'source'}
                                handleOnChange={handleOnChangeSource}
                                options={Format.toSelectOptions({
                                    list: [
                                        { id: 'Supplier', name: 'Supplier' },
                                        { id: 'Department', name: 'Department' },
                                    ],
                                    name: 'name',
                                })}
                            />

                            {source === 'Department' ? (
                                <FormComponent.selectField
                                    label={'Select Department'}
                                    id={'department'}
                                    handleOnChange={handleOnChange}
                                    options={Format.toSelectOptions({
                                        list: departmentList,
                                        name: 'name',
                                    })}
                                />
                            ) : (
                                ''
                            )}

                            {source === 'Supplier' ? (
                                <FormComponent.selectField
                                    label={'Select Supplier'}
                                    id={'supplier'}
                                    handleOnChange={handleOnChange}
                                    options={Format.toSelectOptions({
                                        list: supplierList,
                                        name: 'name',
                                    })}
                                />
                            ) : (
                                ''
                            )}

                            <br />
                            <FormComponent.counterButtons
                                label={'How Many Plants'}
                                id={'howMany'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />
                            <TextField
                                fullWidth
                                label={'Reason'}
                                id={'reason'}
                                onKeyPress={handleOnKeyPress}
                                onChange={handleOnChange}
                                placeholder={'Reason'}
                                margin="normal"
                                type={type || 'text'}
                            />
                            <FormComponent.textArea label={'Note'} id={'note'} handleOnChange={handleOnChange} />
                        </Box>
                    );
                case ActionDefinitionEnum.IMPORT_PLANTS:
                    return (
                        <Box mb={2}>
                            <FormComponent.selectField
                                label={'Select Supplier'}
                                id={'supplier'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: supplierList,
                                    name: 'name',
                                })}
                            />

                            <FormComponent.selectField
                                label={'Select Strain'}
                                id={'strain'}
                                handleOnChange={handleOnChange}
                                options={Format.toSelectOptions({
                                    list: strainList,
                                    name: 'name',
                                })}
                            />

                            <FormComponent.counterButtons
                                label={'How Many Plants'}
                                id={'howMany'}
                                handleOnChange={handleOnChange}
                                handleOnKeyPress={handleOnKeyPress}
                            />
                        </Box>
                    );
            }
        }
    };

    const buildEditDialogActions = () => {
        return (
            <React.Fragment>
                <Button onClick={handleSubmit} variant="contained" size="large" color="primary">
                    Save
                </Button>{' '}
                <Button onClick={handleOnCancel} variant="contained" size="large" color="inherit">
                    Cancel
                </Button>
            </React.Fragment>
        );
    };

    const handleOnCloseDialog = () => {
        // dispatcher({type: Action.setReset, payload: Math.random()});
    };

    return (
        <DialogFormComponent
            params={{
                content: buildEditDialogContent(),
                actions: buildEditDialogActions(),
                title: title,
                onClose: handleOnCloseDialog,
            }}
            dialog={{ dialog: dialog, setDialog: setDialog }}
        />
    );
};

export default BuildActionDefinitionTypeDialog;
