import {Request} from "./Request";
import {Mode} from "app/models/Model";

export class Post extends Request implements Mode {
    method = 'POST';

    constructor(action: string) {
        super();
    }

}