import React, {useEffect, useState} from 'react';
import RoomModel from "app/models/RoomModel";
import Model from "app/models/Model";
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import DepartmentModel from "app/models/DepartmentModel";
import {Action} from "../../models/Model/State";


export const Styles: any = makeStyles((theme) => ({
    options: {
        padding: '20px',
    },
    select: {
        fontSize: '12px',
    },
    inline: {
        float: 'left',
        width: '150px',
        paddingRight: '5px'
    }
}));

class Cache {
    static departmentList: any = undefined;
    static roomList: any = {};
}

export default function DepartmentRoomSelectComponent(props: any) {
    const styles = Styles();
    const {state, setList, dispatch} = props;
    const model = Model.prepare({Room: new RoomModel(), Department: new DepartmentModel()});
    const [departmentList, setDepartmentList] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    let departmentId = '';

    useEffect(() => {
        if (Cache.departmentList === undefined) {
            model.Department.fetch("AllDepartments").with({set: setDepartmentList, onSet: onSetDepartment});
        } else {
            setDepartmentList(Cache.departmentList);
            onSetDepartment(Cache.departmentList);
        }
    }, []);

    const onSetDepartment = (payload: any) => {
        if (payload?.length > 0) {
            Cache.departmentList = payload;
            const event = {target: {value: payload[0]['id']}};
            onChangeDepartment(event).then();
        }
    }

    const onChangeDepartment = async (event: any) => {
        departmentId = event.target.value;
        if (departmentId.length <= 0) return;

        dispatch({type: Action.setFilter, payload: {departmentId: departmentId}});
        if (Cache.roomList[departmentId] === undefined) {
            await model.Room.fetch("ByDepartment").with({set: setRoomList, onSet: onSetRoom, params: {id: departmentId, skip: 0, limit: 15}});
        } else {
            onSetRoom(Cache.roomList[departmentId]);
        }
    }

    const onSetRoom = (payload: any) => {
        if (payload?.length > 0) {
            let batches: any = {}
            if (Object.keys(payload).length <= 0) return setRoomList([]);

            Cache.roomList[departmentId] = payload;
            setSelectedDepartment(departmentId);
            batches = payload[0].batches;
            setSelectedRoom(batches.room);
            setRoomList(payload);
            setList(batches);
            dispatch({type: Action.setList, payload: batches});
        }
    }

    const onChangeRoom = async (event: any) => {
        const roomId = event.target.value;
        let batches: any = {}
        setSelectedRoom(roomId);

        Cache.roomList[selectedDepartment].forEach((result: any) => {
            if (result.id == roomId) {
                batches = result.batches;
                return;
            }
        });
        setList(batches)
        dispatch({type: Action.setList, payload: batches});
    }

    const buildDepartmentOptions = () => {
        const options: any = [];
        departmentList.forEach((option: any) => {
            options.push(<option key={option.id} value={option.id} className={styles.options}>{option.name}</option>)
        });
        return options;
    }

    const buildRoomOptions = () => {
        const options: any = [];
        if (roomList == undefined) return options;

        roomList.forEach((option: any) => {
            options.push(<option key={option.id} value={option.id} className={styles.options}>{option.name}</option>)
        });
        return options;
    }

    const Wrap = (content: any) => {
        return <div className={styles.inline}>{content.children}</div>
    }

    const buildDepartment = () => {
        const selected = state.selected['department'] || selectedDepartment;
        return <Wrap>
            <TextField select fullWidth id={'select-department'} label={''} value={selected}
                       onChange={onChangeDepartment} SelectProps={{native: true}} className={styles.select}>
                {buildDepartmentOptions()}
            </TextField>
        </Wrap>
    }

    const buildRoom = () => {
        if (selectedDepartment == '') return <div/>;
        const selected = selectedRoom || state.selected['room'];
        return <Wrap>
            <TextField select fullWidth id={'select-room'} label={''} value={selected}
                       onChange={onChangeRoom} SelectProps={{native: true}}>
                {buildRoomOptions()}
            </TextField>
        </Wrap>
    }

    return (
        <div className={'filter-box'}>{buildDepartment()}{buildRoom()}</div>
    );
}
