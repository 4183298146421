import Config from "app/configs/Config";
import Model, {Describe} from "./Model";

export default class SupplierModel extends Model implements Describe {
    config = {
        actions: {
            AllSuppliers: {path: Config.PATH_SUPPLIER},
            SaveData: {path: Config.PATH_SUPPLIER},
            UpdateData: {path: Config.PATH_SUPPLIER},
            DeleteData: {path: Config.PATH_SUPPLIER},
        }
    }
}