import React, {useEffect, useState} from 'react';
import {w3cwebsocket as W3CWebSocket} from "websocket";
import UserModel from "app/models/UserModel";
import Config from "app/configs/Config";
import Model from "app/models/Model";

enum SocketCommand { COMMAND_LOGOUT = 'COMMAND_LOGOUT'}

const SocketService = (props?: any) => {
    const model = Model.prepare({User: new UserModel()});
    const [userData, setUserData] = useState({_id: ''});
    let client: any = null;

    useEffect(() => {
        model.User.fetch("Current").with({set: setUserData});
        client = new W3CWebSocket(Config.SOCKET_HOST + Config.SOCKET_PORT);
        client.onopen = () => {
            console.log('Web socket Client Connected');
        };
        client.onmessage = (message: any) => {
            process(SocketCommand.COMMAND_LOGOUT, message.data);
        };
        client.onclose = () => {
            console.log('Closing');
        };
    }, []);

    const process = (command: any, userId: any) => {
        if (isLogout(command)) {
            if (userData['_id'].trim() == userId) {
                model.User.logout();
                location.reload()
            }
        }
    }

    const isLogout = (command: string) => {
        return command == SocketCommand.COMMAND_LOGOUT;
    }

    return props != null ? props.children : <div/>
}

export class SocketSend {
    client: any = null;

    constructor() {
        this.client = new W3CWebSocket(Config.SOCKET_HOST + Config.SOCKET_PORT);
        this.client.onopen = () => {
            console.log('SocketSend Client Connected');
        };
        this.client.onclose = () => {
            console.log('Closing');
        };
    }

    send = (id: string) => {
        this.client.send(id.toString())
    }
}

export default SocketService;