import {Request} from "./Request";
import {Args, Mode} from "app/models/Model";

export class Put extends Request implements Mode {
    method = 'PUT';

    constructor(action: string) {
        super();
    }

    perform = async (args: Args) => {
        return new Promise(async () => {
            return this.request(args);
        })
    }
}