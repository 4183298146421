export enum Action {
    setIsWorking,
    setData,
    setIsButtonDisabled,
    setIsSuccess,
    setIsFailed,
    setIsError,
    setList,
    setSelected,
    setSingleUpdate,
    setPageInfo,
    setFilter,
    setReset
}

export const Dispatch = {
    isWorking: false,
    data: {},
    isButtonDisabled: false,
    isSuccess: false,
    isFailed: false,
    isError: false,
    list: {},
    selected: {},
    singleUpdate: {},
    filter: {},
    pageInfo: {},
    reset: ""
};

const data: any = {};
export const Reducer = (state: typeof Dispatch, action: any): {
    data: any,
    isWorking: boolean,
    isButtonDisabled: boolean,
    isSuccess: boolean,
    isFailed: boolean,
    isError: boolean,
    list: any,
    selected: any,
    filter: any,
    singleUpdate: any,
    pageInfo: any,
    reset: any
} => {
    switch (action.type) {
        case Action.setData:
            const key = Object.keys(action.payload)[0];
            data[key] = action.payload[key];
            return {...state, data: data};
        case Action.setIsWorking:
            return {...state, isWorking: action.payload};
        case Action.setIsButtonDisabled:
            return {...state, isButtonDisabled: action.payload};
        case Action.setIsSuccess:
            return {...state, isSuccess: action.payload};
        case Action.setIsFailed:
            return {...state, isSuccess: action.payload};
        case Action.setIsError:
            return {...state, isSuccess: action.payload};
        case Action.setList:
            return {...state, list: action.payload};
        case Action.setSelected:
            state.data = {};
            return {...state, selected: action.payload};
        case Action.setFilter:
            return {...state, filter: action.payload};
        case Action.setSingleUpdate:
            state.data = action.payload;
            state.selected = state.data;
            return {...state, selected: action.payload};
        case Action.setPageInfo:
            return {...state, pageInfo: action.payload};
        case Action.setReset:
            state.data = {};
            state.selected = {};
            state.pageInfo = {};
            return {...state, reset: action.payload};
        default:
            return {...state};
    }
};
