import {useEffect, useState} from "react";
import UserModel from "app/models/UserModel";
import Model from "app/models/Model";
import ErrorComponent from "app/pages/components/ErrorComponent";

let allowedPageGuardContent = <></>;
const PagePermissionGuard = (props: any) => {
    const model = Model.prepare({User: new UserModel()});
    const [userData, setUserData] = useState({});
    const [refresh, setRefresh] = useState(0);
    let pathName = "";

    useEffect(() => {
        model.User.fetch("Current").with({set: setUserData, onSet: onSetUser});
    }, [refresh]);

    const onSetUser = (payload: any) => {
        pathName = window.location.pathname;
        pathName = pathName.substring(1);
        const split = pathName.split("/");
        if (payload.allowedPages === undefined) return false;
        const isAllowed = payload.allowedPages.indexOf(split[0]) !== -1;
        allowedPageGuardContent = props.children;
        if (!isAllowed) allowedPageGuardContent = <ErrorComponent/>;
        setRefresh(Math.random());
    }
    return allowedPageGuardContent;
}

export default PagePermissionGuard
