import {Store} from "app/stores/Store";
import {Get} from "./Model/Get";
import {Post} from "./Model/Post";
import {Put} from "./Model/Put";
import {Delete} from "./Model/Delete";

export type Constructor = new (...args: any[]) => any;
export type Config = { path: string, pattern: string };
export type Params = { id: string | number, any?: string | number, limit?: number, skip?: number };
export type Setter = { set: any, onSet?: any, params?: Params, dispatch: undefined, cache?: boolean } | any
export type Mode = { method: string, perform: (args: Args) => any }

export interface Describe {
    config: { actions: any }
}

export type Args = {
    store: typeof Store,
    setter: Setter,
    config: Config,
    action: string,
    state: any,
    id: string
}

export default class Model {
    config: any;
    private args: any;
    private action: any;

    constructor(augment: any = {}) {
        Object.assign(this, augment)
    }

    static prepare<T extends typeof Model, U>(this: T, augment?: U) {
        return new this(augment) as InstanceType<T> & U;
    }

    fetch(name: string) {
        this.args = {store: Store};
        this.args.config = this.config.actions[name];
        this.action = new Get(this.args.action = name);
        return this;
    }

    relentless(state: any = {}, name: string) {
        this.args = {store: Store};
        this.args.state = state;
        this.args.config = this.config.actions[name];
    }

    add(state: any = {}) {
        const name = 'SaveData';
        this.relentless(state, name);
        this.action = new Post(this.args.action = name);
        return this;
    }

    _getSelectedId(state: any = {}) {
        return state.selected.id !== undefined ? state.selected.id : state.selected._id
    }

    update(state: any = {}) {
        const name = 'UpdateData';
        this.relentless(state, name);
        this.args.id = this._getSelectedId(state);
        this.action = new Put(this.args.action = name);
        return this;
    }

    delete(state: any = {}) {
        const name = 'DeleteData';
        this.relentless(state, name);
        this.args.id = this._getSelectedId(state);
        this.action = new Delete(this.args.action = name);
        return this;
    }

    with<T extends Constructor>(setter: Setter) {
        this.args.setter = setter;
        if (this.args.id !== undefined) this.args.setter['params'] = {id: this.args.id};
        this.action.perform(this.args);
    }
}

export const ModelReducer = (state: any, action: any) => {
    return {...state, data: action.payload};
};


