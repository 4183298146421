import Config from "app/configs/Config";
import Model, {Describe} from "./Model";

export default class StrainModel extends Model implements Describe {
    config = {
        actions: {
            AllStrains: {path: Config.PATH_STRAIN},
            SaveData: {path: Config.PATH_STRAIN},
            UpdateData: {path: Config.PATH_STRAIN},
            DeleteData: {path: Config.PATH_STRAIN},
        }
    }
}