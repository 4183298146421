import React, {useEffect, useReducer} from 'react';
import {AuthResult, AuthService} from 'app/services/auth/AuthService';
import {RoutePath} from 'app/routes/Route';
import {LoginStyle} from './LoginStyle';
import {LoginDefault, LoginReducer, LoginSelector} from './LoginReducer';
import UserModel from "app/models/UserModel";
import {Store} from "app/stores/Store";
import styled from "styled-components";
import ButtonComponent from 'app/pages/components/ButtonComponent';
import {Avatar, Box, Card, CardContent, Grid, Link, TextField, Typography} from "@mui/material";

export class WidgetStyled {
    static DivProgress = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
    `;
    static DivErrorMessage = styled.div`
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: red;
    `;
}

const LoginPage = () => {
    const userModel = new UserModel(Store);
    const style = LoginStyle();
    const [state, dispatch] = useReducer(LoginReducer, LoginDefault);

    useEffect(() => {
        const isValid = state.email.trim() && state.password.trim();
        userModel.logout();
        isValid
            ? dispatch({
                type: LoginSelector.setIsButtonDisabled,
                payload: false,
            })
            : dispatch({
                type: LoginSelector.setIsButtonDisabled,
                payload: true,
            });
    }, [state.email, state.password]);

    const onHandleSubmit = () => {
        const authService = new AuthService();
        const _failed = () => {
            dispatch({
                type: LoginSelector.statusFailed,
                payload: 'Invalid email or password',
            });
        };

        const _success = () => {
            window.location.href = RoutePath.DASHBOARD;
        };

        authService.login({email: state.email, password: state.password}).then((result: AuthResult) => {
            dispatch({
                type: LoginSelector.setIsAuthenticating,
                payload: false,
            });
            result.type ? _success() : _failed();
        });
    };

    const handleSubmit = () => {
        dispatch({
            type: LoginSelector.setIsAuthenticating,
            payload: true,
        });
        onHandleSubmit();
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        const {key} = event;
        if (key === 'Enter') {
            state.isButtonDisabled || handleSubmit();
        }
    };

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch({
            type: LoginSelector.setEmail,
            payload: event.target.value,
        });
    };

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch({
            type: LoginSelector.setPassword,
            payload: event.target.value,
        });
    };

    return (
        <form noValidate autoComplete="off" className={style.container}>
            <Card className={style.card} elevation={1}>
                <CardContent>
                    <Box sx={{textAlign: 'center'}}>
                        <Avatar alt="Batch Management" src="/assets/img/logo.png" sx={{backgroundColor: 'grey', margin: 'auto', marginBottom: '9px'}}/>
                        <Typography gutterBottom variant="h5" component="div">
                            Sign in
                        </Typography>
                    </Box>
                    <Grid pb={2}>
                        <TextField
                            error={state.isError}
                            fullWidth
                            id="email"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            margin="normal"
                            onChange={handleEmailChange}
                            onKeyPress={handleKeyPress}
                        />
                        <TextField
                            error={state.isError}
                            fullWidth
                            id="password"
                            type="password"
                            label="Password"
                            placeholder="Password"
                            margin="normal"
                            onChange={handlePasswordChange}
                            onKeyPress={handleKeyPress}
                        />
                        <Link href="/reset-password">Forgot password?</Link>
                    </Grid>
                    <ButtonComponent fullWidth={true} handleClick={handleSubmit} reducer={state} label={'Login'}
                                     isStatus={state.isAuthenticating}/>
                    <WidgetStyled.DivErrorMessage>{state.helperText}</WidgetStyled.DivErrorMessage>
                </CardContent>
            </Card>
        </form>
    );
};

export default LoginPage;
