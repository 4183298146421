import {Box, Typography} from "@mui/material";
import * as React from "react";
import WrapperComponent from "./WrapperComponent";

const ErrorComponent = () => {
    return (
        <React.Fragment>
            <WrapperComponent>
                <Box m="auto" mt={'10%'} sx={{width: '50%'}}>
                    <Typography align="center">
                        <h1>Not Allowed</h1>
                        <h6>Sorry, You Are Not Allowed to Access This Page</h6>
                    </Typography>
                </Box>
            </WrapperComponent>
        </React.Fragment>
    );
}

export default ErrorComponent;
