export enum ResetPasswordSelector {
    setEmail,
    setPassword,
    setRePassword,
    setIsButtonDisabled,
    setIsError,
    setIsAuthenticating,
    statusFailed,
    statusSuccess,
}

type ResetPasswordState = {
    email: string;
    password: string;
    rePassword: string;
    isButtonDisabled: boolean;
    helperText: string;
    isAuthenticating: boolean;
    isError: boolean;
};

type ResetPasswordAction =
    | { type: ResetPasswordSelector.setEmail; payload: string }
    | { type: ResetPasswordSelector.setPassword; payload: string }
    | { type: ResetPasswordSelector.setRePassword; payload: string }
    | { type: ResetPasswordSelector.setIsButtonDisabled; payload: boolean }
    | { type: ResetPasswordSelector.statusSuccess; payload: string }
    | { type: ResetPasswordSelector.statusFailed; payload: string }
    | { type: ResetPasswordSelector.setIsError; payload: boolean }
    | { type: ResetPasswordSelector.setIsAuthenticating; payload: boolean };

export const ResetPasswordDefault: ResetPasswordState = {
    email: '',
    password: '',
    rePassword: '',
    isButtonDisabled: true,
    helperText: '',
    isAuthenticating: false,
    isError: false,
};

export const ResetPasswordReducer = (state: ResetPasswordState, action: ResetPasswordAction): ResetPasswordState => {
    switch (action.type) {
        case ResetPasswordSelector.setIsAuthenticating:
            return {
                ...state,
                isAuthenticating: action.payload,
            };
        case ResetPasswordSelector.setEmail:
            return {
                ...state,
                email: action.payload,
            };
        case ResetPasswordSelector.setPassword:
            return {
                ...state,
                password: action.payload,
            };
        case ResetPasswordSelector.setRePassword:
            return {
                ...state,
                password: action.payload,
            };
        case ResetPasswordSelector.setIsButtonDisabled:
            return {
                ...state,
                isButtonDisabled: action.payload,
            };
        case ResetPasswordSelector.statusSuccess:
            return {
                ...state,
                helperText: action.payload,
                isError: false,
            };
        case ResetPasswordSelector.statusFailed:
            return {
                ...state,
                helperText: action.payload,
                isError: true,
            };
        case ResetPasswordSelector.setIsError:
            return {
                ...state,
                isError: action.payload,
            };
    }
};
