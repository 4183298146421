import Config from "app/configs/Config";
import Model from "./Model";

export default class RoomTypeModel extends Model {
    config = {
        actions: {
            AllRoomTypes: {path: Config.PATH_ROOM_TYPE},
            SaveData: {path: Config.PATH_ROOM_TYPE},
            UpdateData: {path: Config.PATH_ROOM_TYPE},
            DeleteData: {path: Config.PATH_ROOM_TYPE},
        }
    };
}
