import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useReducer, useState} from "react";
import WrapperComponent from "./components/WrapperComponent";
import DialogFormComponent from "./components/DialogFormComponent";
import Model from "app/models/Model";
import {Action, Dispatch, Reducer} from "app/models/Model/State";
import SimpleTableComponent, {TableAlignmentTypes, TableComponentTypes} from "app/pages/components/SimpleTableComponent";
import SimpleTableBoxComponent from "app/pages/components/SimpleTableBoxComponent";
import FormComponent from "./components/FormComponent";
import ActionModel from "app/models/ActionModel";
import SimpleTablePaginateComponent from "./components/SimpleTablePaginateComponent";
import FormatTime from "app/utils/FormatTime";
import Format from "app/utils/Format";
import ActionDefinitionModel from "app/models/ActionDefinitionModel";
import Strings from "app/utils/Strings";
import RoleModel from "app/models/RoleModel";
import RoomBatchSelectComponent from "./components/RoomBatchSelectComponent";
import UserModel from "app/models/UserModel";
import BatchInfoDialogComponent from "./components/BatchInfoDialog";
import StrainModel from "../models/StrainModel";

const ActionPage = () => {
    const model = Model.prepare({Action: new ActionModel(), Strain: new StrainModel(),ActionDefinition: new ActionDefinitionModel(), User: new UserModel()});
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [actionList, setActionList] = useState([]);
    const [actionDefinitionList, setActionDefinitionList] = useState([]);
    const [strainList, setStrainList] = useState([]);
    const [userInfo, setUserInfo] = useState({role: null});
    const [eaDialog, setEADialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [batchInfoDialog, setBatchInfoDialog] = useState(false);
    const [batchInfo, setBatchInfo] = useState({});
    const tableComponentTypes = TableComponentTypes;
    const tableAlignmentTypes = TableAlignmentTypes;

    useEffect(() => {
        model.User.fetch("Current").with({set: setUserInfo});
        model.Action.fetch("ByDepartment").with({set: setActionList, dispatch: dispatch, params: {id: 0, skip: 0, limit: 15}});
        model.ActionDefinition.fetch("AllActionDefinitions").with({set: setActionDefinitionList, params: {skip: 0, limit: 15}});
        model.Strain.fetch("AllStrains").with({set: setStrainList});
    }, [])

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            setEADialog(false);
        };
        dispatch({type: Action.setIsWorking, payload: true});

        if (state.pageInfo['action'] === 'add') model.Action.add(state).with({set: setActionList, onSet: _onSet});
        else model.Action.update(state).with({set: setActionList, onSet: _onSet});
    };

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({type: Action.setData, payload: payload});
    }

    const handleOnClickStatus = (data: any) => {
        data.status = data.status == 'active' ? 'inActive' : 'active';
        dispatch({type: Action.setSingleUpdate, payload: data});
        model.Action.update(state).with({set: setActionList});
    }

    const handleOnEditClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        dispatch({type: Action.setPageInfo, payload: {title: 'Edit ' + data.description, action: 'edit'}});
        setEADialog(true);
    }

    const handleOnDeleteClick = (data: any) => {
        dispatch({type: Action.setSelected, payload: data});
        dispatch({type: Action.setPageInfo, payload: {title: 'Delete ' + data.description}});
        setDeleteDialog(true);
    }

    const handleOnDeleteConfirmed = (event: any) => {
        const _onSet = (payload: any) => setDeleteDialog(false);
        model.Action.delete(state).with({set: setActionList, onSet: _onSet});
    }

    const handleOnAddClick = (event: any) => {
        if (actionDefinitionList?.length > 0) {
            setEADialog(true);
            dispatch({type: Action.setData, payload: {description: actionDefinitionList[0]['description']}});
            dispatch({type: Action.setPageInfo, payload: {title: 'Add Action', action: 'add'}});
        }
    }

    const handleOnCloseDialog = () => {
        dispatch({type: Action.setReset, payload: Math.random()});
    }

    const handlePaginateOnChange = (event: any, value: string) => {
        model.Action.fetch("ByDepartment").with({set: setActionList, dispatch: dispatch, params: {id: 0, skip: parseInt(value) * 2 + 1, limit: 15}});
    }

    const buildEditDialogContent = () => {
        return <Box mb={2}>
            <RoomBatchSelectComponent state={state} dispatcher={dispatch} set={setActionList} handleOnChange={handleOnChange}/>
            <FormComponent.selectField state={state} label={'Action'} id={"description"} handleOnChange={handleOnChange}
                                       options={Format.toSelectOptions({list: actionDefinitionList, name: 'description', nameOnly: true})}/>
            <FormComponent.textArea state={state} label={'Note'} id={"note"} handleOnChange={handleOnChange}/>
            <FormComponent.groupCheckBox state={state} label={'Select Roles'} id={"role"} handleOnChange={handleOnChange} options={RoleModel.getRole()}/>
        </Box>;
    }

    const buildEditDialogActions = () => {
        return <React.Fragment><Button onClick={handleOnSubmit} variant="contained" size="large" color="primary">Save</Button></React.Fragment>;
    }

    const buildDeleteDialogContent = () => {
        return <React.Fragment><Typography gutterBottom>Are you sure you want to delete this {state.selected['name']}?</Typography></React.Fragment>;
    }

    const buildDeleteDialogActions = () => {
        return <React.Fragment>
            <Button onClick={() => setDeleteDialog(false)} variant="contained" size="large" color="inherit">Cancel</Button>
            <FormComponent.button label={'Delete'} handleClick={handleOnDeleteConfirmed}/>
        </React.Fragment>;
    }

    const buildFilterBox = () => {
        return <RoomBatchSelectComponent state={state} set={setActionList} type={'inline'}/>
    }

    const buildStatusIcon = (data: any) => data.status === 'active' ? 'checked' : 'close';

    const buildActions = () => {
        if (userInfo === null) return [];
        if (RoleModel.isSuperAdmin(userInfo['role'])) {
            return [{icon: 'edit', event: handleOnEditClick}, {icon: 'delete', event: handleOnDeleteClick}]
        }
        return [];
    }

    const showBatchInfo = (data: any) => {
        if (data.batch) {
            const getBatch = data;
            getBatch.id = data.batch;
            setBatchInfo(getBatch);
            setBatchInfoDialog(true);
        }
    }

    return (
        <React.Fragment>
            <BatchInfoDialogComponent params={{
                setBatch: batchInfo,
                strainList: strainList
            }}
                                      dialog={{dialog: batchInfoDialog, setDialog: setBatchInfoDialog}}/>

            <DialogFormComponent params={{content: buildEditDialogContent(), actions: buildEditDialogActions(), title: state.pageInfo.title, onClose: handleOnCloseDialog}}
                                 dialog={{dialog: eaDialog, setDialog: setEADialog}}/>
            <DialogFormComponent params={{content: buildDeleteDialogContent(), actions: buildDeleteDialogActions(), title: state.pageInfo.title}}
                                 dialog={{dialog: deleteDialog, setDialog: setDeleteDialog}}/>
            <WrapperComponent>
                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', height: '820px'}} className={'manage'}>
                    <SimpleTableBoxComponent title={'Action Log'} addOn={buildFilterBox()} show={false}/>
                    <SimpleTableComponent params={{
                        headers: [
                            {
                                display: 'My Action',
                                type: tableComponentTypes.PLAIN,
                                field: "description",
                                withEdit: RoleModel.isSuperAdmin(userInfo['role']) ? showBatchInfo : null
                            },
                            {display: 'Note', type: tableComponentTypes.PLAIN, field: "note", format: Strings.toEllipses},
                            {display: 'Status', type: tableComponentTypes.BUTTONS, components: [{icon: buildStatusIcon, event: handleOnClickStatus}]},
                            {display: 'Created', type: tableComponentTypes.PLAIN, field: "dateCreated", format: FormatTime.toDateTime},
                            {display: 'Option', type: tableComponentTypes.BUTTONS, align: tableAlignmentTypes.LEFT, components: buildActions()}],
                        data: actionList,
                    }}/>
                    <SimpleTablePaginateComponent list={actionList} setList={setActionList} limit={15} onChange={handlePaginateOnChange}/>
                </Box>
            </WrapperComponent>
        </React.Fragment>
    )
}

export default ActionPage;
