import React, { useEffect, useReducer, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    styled,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import { Styles } from './dashboard/Styles';
import WrapperComponent from './components/WrapperComponent';
import DialogFormComponent from './components/DialogFormComponent';
import { Action, Dispatch, Reducer } from 'app/models/Model/State';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Model from 'app/models/Model';
import UserModel from 'app/models/UserModel';
import FarmModel from 'app/models/FarmModel';
import RoleModel from 'app/models/RoleModel';
import FormComponent from './components/FormComponent';
import Format from 'app/utils/Format';

const RootStyle = styled(Card)(({ theme }) => ({
    'margin-top': '32px',
    width: '100%',
    boxShadow: 'none',
    textAlign: 'center',
    marginBottom: '10px',
    padding: theme.spacing(1, 0),
}));

const HeaderStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    padding: '20px',
    boxShadow: 'none',
    display: 'flex',
    'flex-direction': 'row',
}));

const ProfilePage = () => {
    const styles = Styles();
    const model = Model.prepare({ User: new UserModel(), Farm: new FarmModel() });
    const [state, dispatch] = useReducer(Reducer, Dispatch);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        role: '',
        phone: '',
        photo: '',
        farm: '',
        allowedPages: [],
    });
    const [userInfo, setUserInfo] = useState(userData);
    const [farmList, setFarmList] = useState([]);
    const [editDialog, setEditDialog] = useState(false);

    useEffect(() => {
        model.User.fetch('Current').with({ set: setUserInfo, onSet: setUser });
        model.Farm.fetch('AllFarms').with({ set: setFarmList, params: { id: 'all' } });
    }, []);

    const setUser = (payload: any) => {
        model.User.fetch('ById').with({ set: setUserData, dispatch: dispatch, params: { id: payload._id } });
    };

    const handleOnKeyPress = (event: any) => {
        const { key } = event;
        if (key === 'Enter') state.isButtonDisabled || handleOnSubmit(event);
    };

    const handleOnChange = (event: any) => {
        const payload: any = [];
        payload[event.target.id] = event.target.value;
        dispatch({ type: Action.setData, payload: payload });
    };

    const _buildEditDialogContent = () => {
        return (
            <React.Fragment>
                <FormComponent.textField
                    state={state}
                    label={'Name'}
                    id={'name'}
                    handleOnChange={handleOnChange}
                    handleOnKeyPress={handleOnKeyPress}
                />
                <FormComponent.textField
                    state={state}
                    label={'Email'}
                    id={'email'}
                    handleOnChange={handleOnChange}
                    handleOnKeyPress={handleOnKeyPress}
                />
                <FormComponent.selectField
                    state={state}
                    label={'Farm'}
                    id={'farm'}
                    handleOnChange={handleOnChange}
                    options={Format.toSelectOptions({ list: farmList, name: 'name' })}
                />
                <FormComponent.selectField
                    state={state}
                    label={'Role'}
                    id={'role'}
                    handleOnChange={handleOnChange}
                    options={RoleModel.getRole()}
                />
                <TextField
                    fullWidth
                    label="Phone"
                    id="phone"
                    value={state.data['phone'] || state.selected['phone']}
                    onChange={handleOnChange}
                    margin="normal"
                />
            </React.Fragment>
        );
    };

    const _buildEditDialogActions = () => {
        return (
            <React.Fragment>
                <Button onClick={handleOnSubmit} variant="contained" size="large" color="primary">
                    Save
                </Button>
            </React.Fragment>
        );
    };

    const handleOnSubmit = (event: any) => {
        const _onSet = (payload: any) => {
            const data = { ...payload[0], ...state.data };
            setUserData(data);
            setEditDialog(false);
        };
        dispatch({ type: Action.setIsWorking, payload: true });
        model.User.update(state).with({ set: setUserData, onSet: _onSet });
    };

    const editProfile = () => {
        dispatch({ type: Action.setSelected, payload: userData });
        setEditDialog(true);
    };

    const buildAllowedPages = () => {
        if (!userData.allowedPages) {
            return;
        }

        return userData.allowedPages.join(', ');
    };

    return (
        <React.Fragment>
            <DialogFormComponent
                params={{ content: _buildEditDialogContent(), actions: _buildEditDialogActions(), title: 'Edit' }}
                dialog={{ dialog: editDialog, setDialog: setEditDialog }}
            />
            <WrapperComponent>
                <Toolbar />
                <Container fixed sx={{ mt: 6, mb: 4 }} maxWidth={false}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                        <Grid container md={5} xs={5}>
                            <HeaderStyle>
                                <Grid item md={2} xs={2}>
                                    <Avatar alt={userData.name} src={userData.photo} sx={{ width: 86, height: 86 }} />
                                </Grid>
                                <Grid
                                    item
                                    pl={1}
                                    md={8}
                                    xs={8}
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                >
                                    <Box
                                        sx={{
                                            typography: 'body1',
                                            fontSize: 'h6.fontSize',
                                            fontWeight: 'medium',
                                        }}
                                    >
                                        {' '}
                                        {userData.name}
                                    </Box>
                                    <Box sx={{ typography: 'body1' }}> {userData?.email}</Box>
                                </Grid>
                                <Grid container md={12} xs={12}>
                                    <Button
                                        onClick={editProfile}
                                        sx={{ display: 'flex', height: 'fit-content', marginLeft: 'auto' }}
                                        variant="contained"
                                        startIcon={<AddCircleOutlineIcon />}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </HeaderStyle>
                        </Grid>

                        <Grid container md={5} xs={5}>
                            <RootStyle>
                                <Grid item>
                                    <Box pt={2} pb={2} pl={1}>
                                        <Typography variant="h6">About me</Typography>
                                    </Box>
                                </Grid>
                                <Divider sx={{ ml: 4, mr: 4 }} flexItem />
                                <Grid
                                    pt={2}
                                    pb={2}
                                    pl={4}
                                    item
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box sx={{ fontWeight: 'medium' }}>Phone:</Box>
                                        <Box sx={{ typography: 'body1' }} ml={1}>
                                            {userData?.phone}
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box sx={{ fontWeight: 'medium' }}>Farm:</Box>
                                        <Box sx={{ typography: 'body1' }} ml={1}>
                                            {Format.populate({ list: farmList, id: userData.farm, name: 'name' })}
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row' }} mt={1}>
                                        <Box sx={{ fontWeight: 'medium' }}>Allowed Pages:</Box>
                                    </Box>
                                    <Box sx={{ textAlign: 'left' }}>{buildAllowedPages()}</Box>
                                </Grid>
                            </RootStyle>
                        </Grid>
                    </Grid>
                </Container>
            </WrapperComponent>
        </React.Fragment>
    );
};
export default ProfilePage;
