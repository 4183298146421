import React from 'react';
import {styled} from "@mui/styles";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from "@mui/material";
import {Action} from "app/models/Model/State";

const RootStyle = styled(Box)(({theme}) => ({
    minWidth: '550px',
}));

const DialogFormComponent = (props: any) => {
    const {content, actions, title, onClose, dispatch} = props.params;
    const {dialog, setDialog} = props.dialog;

    const handleOnClose = () => {
        setDialog(false);
        if (dispatch !== undefined) dispatch({type: Action.setReset, payload: Math.random()});
        if (onClose !== undefined) onClose();
    }

    return <Dialog open={dialog} onClose={handleOnClose}>
        <FormControl variant="outlined">
            <RootStyle>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers> {content} </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </RootStyle>
        </FormControl>
    </Dialog>
}

export default DialogFormComponent;
