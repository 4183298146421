import {Pagination} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

const SimpleTablePaginateComponent = (props: any) => {
    const {list, setList, limit, onChange} = props

    const buildPaginate = () => {
        if (list == null) return <div/>
        if (list.length <= limit - 1) return <div/>

        return <Box mb={1} mt={1.5}>
            <Pagination count={5} variant="outlined" shape="rounded" sx={{textAlign: "right"}} onChange={onChange}/>
        </Box>
    }

    return buildPaginate();
}

export default SimpleTablePaginateComponent;