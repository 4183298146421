import Route from 'app/routes/Route';
import {Provider} from "react-redux";
import {Store} from "app/stores/Store";
import ThemeConfig from "./themes";

export default function App() {
    return (
        <Provider store={Store}>
            <ThemeConfig>
                <Route/>
            </ThemeConfig>
        </Provider>
    );
}
