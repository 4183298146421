import "./assets/css/styles.css";
import "./assets/css/timeline.css";
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import App from 'app/App';
import * as serviceWorker from './common/ServiceWorker';
import reportWebVitals from './common/ReportWebVitals';

ReactDOM.render(
    <HelmetProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </HelmetProvider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
reportWebVitals();
