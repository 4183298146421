import React, {useEffect, useState} from 'react';
import DepartmentModel from "app/models/DepartmentModel";
import Model from "app/models/Model";
import {TextField} from "@mui/material";
import {makeStyles} from '@mui/styles';

export const Styles: any = makeStyles((theme) => ({
    options: {
        padding: '20px'
    },
    inline: {
        float: 'left',
        width: '175px',
        paddingRight: '5px'
    }
}));

export default function DepartmentSelectComponent(props: any) {
    const styles = Styles();
    const {handleOnChange} = props;
    const model = Model.prepare({Department: new DepartmentModel()});
    const [departmentList, setDepartmentList] = useState([]);
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        model.Department.fetch("AllDepartments").with({set: setDepartmentList});
    }, []);

    const onChange = (event: any) => {
        const id = event.target.value;
        handleOnChange(id);
        setSelected(id);
        return true;
    }

    return (
        <div className={styles.inline}>
            <TextField select fullWidth id={'department'} label={''} value={selected} onChange={onChange} SelectProps={{native: true}}>
                <option key={'all'} value={'all'} className={styles.options}>All Departments</option>
                {departmentList?.map((option: any) => (
                    <option key={option.id} value={option.id} className={styles.options}>{option.name}</option>
                ))}
            </TextField>
        </div>
    );
}
