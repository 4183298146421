import {makeStyles} from "@mui/styles";

export const Styles: any = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    option: {
        padding: "1px",
        textAlign: "right",
        height: '60px',
        borderRadius: 10,
        boxShadow: "none"
    },
    select: {
        color: 'black',
        fontSize: '12px'
    },
    pullRight: {
        position: 'absolute',
        right: '30px',
    },
    menu: {
        '& span, & svg': {
            fontSize: '0.90em'
        }
    },
    describe: {
        fontSize: '0.75em'
    },
    menuIcon: {
        '& span, & svg': {
            fontSize: '0.89em'
        },
        height: '28px',
        width: '28px'
    },
    maximize: {
        width: '30px',
        position: 'relative',
        float: 'right'
    },
    qr: {
        width: '50px',
        position: 'relative',
        float: 'right'
    }
}));
