import {AxiosInstance} from "app/services/api/ApiService";

const AuthInterceptor = () => {
    const onRequest = () => {
        AxiosInstance.interceptors.request.use(
            async (config) => {
                config.headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };
                return config;
            },
            (error) => {
                Promise.reject(error);
            },
        );
    };

    const onResponse = () => {
        AxiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            async function (error) {
                /*@todo check errors and returns result to store */
                if (error.response.status === 403) {
                }
                return Promise.reject(error);
            },
        );
    };

    onRequest();
    onResponse();
}

export default AuthInterceptor;