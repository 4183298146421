class Format {
    static toSelectOptions(args: { list: any, name: string, nameOnly?: boolean }) {
        const data: any = [];
        if (args.list == undefined) return [];

        args.list.forEach((results: any) => {
            const id = results['id'] || results['_id'];
            data.push({name: results[args.name], id: args.nameOnly ? results[args.name] : id, obj: results})
        });
        return data;
    }

    static toProperCheckbox(args: { list: any }) {
        const data: any = [];
        args.list.forEach((results: any) => {
            const isString = typeof results == 'string';
            let result = results;
            if (isString) result = {name: results, id: results};
            data.push(result);
        });
        return data;
    }

    static populate(args: { list: any, id: string, name?: string }) {
        if (args.list.length <= 0) return args.id;
        let v = 'Unknown';

        args.list.forEach((results: any) => {
            const id = results['_id'] || results['id'];
            if (id == args.id) v = args.name !== undefined ? results[args.name] : args.list;
        });
        return v;
    }
}

export default Format;
