import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from '@mui/styles';
import shapeStyle from 'app/themes/styles/ShapeStyle';
import TypographyStyle from 'app/themes/styles/TypographyStyle';
import GlobalStyle from 'app/themes/styles/GlobalStyle';
import componentsOverride from './customize';
import {createTheme, StyledEngineProvider} from "@mui/material/styles";

ThemeConfig.propTypes = {
    children: PropTypes.node,
};

export default function ThemeConfig({children}) {
    const themeOptions = useMemo(
        () => ({
            shape: shapeStyle,
            typography: TypographyStyle,
        }),
        [],
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <GlobalStyle/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
