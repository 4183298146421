export enum LoginSelector {
    setEmail,
    setPassword,
    setIsButtonDisabled,
    setIsError,
    setIsAuthenticating,
    statusFailed,
    statusSuccess,
}

type LoginState = {
    email: string;
    password: string;
    isButtonDisabled: boolean;
    helperText: string;
    isAuthenticating: boolean;
    isError: boolean;
};

type LoginAction =
    | { type: LoginSelector.setEmail; payload: string }
    | { type: LoginSelector.setPassword; payload: string }
    | { type: LoginSelector.setIsButtonDisabled; payload: boolean }
    | { type: LoginSelector.statusSuccess; payload: string }
    | { type: LoginSelector.statusFailed; payload: string }
    | { type: LoginSelector.setIsError; payload: boolean }
    | { type: LoginSelector.setIsAuthenticating; payload: boolean };

export const LoginDefault: LoginState = {
    email: '',
    password: '',
    isButtonDisabled: true,
    helperText: '',
    isAuthenticating: false,
    isError: false,
};

export const LoginReducer = (state: LoginState, action: LoginAction): LoginState => {
    switch (action.type) {
        case LoginSelector.setIsAuthenticating:
            return {
                ...state,
                isAuthenticating: action.payload,
            };
        case LoginSelector.setEmail:
            return {
                ...state,
                email: action.payload,
            };
        case LoginSelector.setPassword:
            return {
                ...state,
                password: action.payload,
            };
        case LoginSelector.setIsButtonDisabled:
            return {
                ...state,
                isButtonDisabled: action.payload,
            };
        case LoginSelector.statusSuccess:
            return {
                ...state,
                helperText: action.payload,
                isError: false,
            };
        case LoginSelector.statusFailed:
            return {
                ...state,
                helperText: action.payload,
                isError: true,
            };
        case LoginSelector.setIsError:
            return {
                ...state,
                isError: action.payload,
            };
    }
};
